///////////////////////////////////////////////////////////////////////////////
// OnepassFido2Logger.js
// =========
// singleton log class (module pattern)
//
//
///////////////////////////////////////////////////////////////////////////////
// utility function to print message with timestamp to log
// e.g.
//	OnepassFido2Logger.init("debug"); log mode = debug, info, error
//	OnepassFido2Logger.debug("TestClass","debug hello world");
//	OnepassFido2Logger.info("TestClass","info hello world");
//	OnepassFido2Logger.error("TestClass","error hello world");
//
//
// History:
//          1.00: First public release.
//
// AUTHOR: jkmoon(jkmoon@raonsecure.com)
// CREATED: 2018-10-01
//
// Copyright 2018. jkmoon
///////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////
// CONST VARIABLE, FUNCTION
///////////////////////////////////////////////////////////////////////////
const LOG_MODE_DEBUG = "DEBUG";
const LOG_MODE_INFO = "INFO";
const LOG_MODE_ERROR = "ERROR";


///////////////////////////////////////////////////////////////////////////////
var OnepassFido2Logger = (() => {
    "use strict";

    ///////////////////////////////////////////////////////////////////////////
    // private members
    ///////////////////////////////////////////////////////////////////////////
    var version = "1.00";
	var mode = "DEBUG";

    ///////////////////////////////////////////////////////////////////////////
    // get time and date as string with a trailing space
    var getTime = function()
    {
        var now = new Date();
        let hour = "0" + now.getHours();
        hour = hour.substring(hour.length-2);
        let minute = "0" + now.getMinutes();
        minute = minute.substring(minute.length-2);
        let second = "0" + now.getSeconds();
        second = second.substring(second.length-2);
		let milliSeconds = now.getUTCMilliseconds()
        return getDate() + " " + hour + ":" + minute + ":" + second + "." + milliSeconds;
    };
    var getDate = function()
    {
        var now = new Date();
        var year = "" + now.getUTCFullYear();
        var month = "0" + (now.getUTCMonth()+1);
        month = month.substring(month.length-2);
        var date = "0" + now.getUTCDate();
        date = date.substring(date.length-2);
        return year + "-" + month + "-" + date;
    };


    ///////////////////////////////////////////////////////////////////////////
    // public members
    ///////////////////////////////////////////////////////////////////////////
    var self =
    {
		// init Logger class
		init: function(mode) {
			this.setMode(mode);
		},
        // set log mode
		setMode: function(pramMode) {
			mode = pramMode.toUpperCase();
		},
		// get log mode
		getMode: function() {
			return mode;
		},
		
        // print log message
        print: function(className, methodName,  msg, objData) {
			let strMsg = getTime() + " [" + className + "-> "+ methodName + "] " + msg;
			
			if( objData == "" || objData == null || objData == undefined 
				|| ( objData != null && typeof objData == "object" && !Object.keys(objData).length ) ) { 
				console.log(strMsg);
			}  else {
				console.log(strMsg, objData);
			}
        },
		
		// log print mode
		debug: function(className, methodName, msg, objData) {
			if( this.getMode() == LOG_MODE_DEBUG ) {
				this.print(className, methodName, msg, objData);
			}
		},
		info: function(className, methodName, msg, objData) {
			if( this.getMode() != LOG_MODE_ERROR ) {
				this.print(className, methodName, msg, objData);
			}
		},
		error: function(className, methodName, msg, objData) {
			this.print(className, methodName, msg, objData);
		}
    };
    return self;
})();
export default OnepassFido2Logger;