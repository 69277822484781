export const OS_VERSION = {
    OS_WINDOW : '1',
    OS_MAC : '2',
    OS_ANDROID : '3',
    OS_IOS : '4',
    OS_ETC : '5'
}

export const AAID = {
    Biometrics : '2',
    PIN : '4',
    Pattern : '128'
}

// prod
export const FT_SERVER_URL = "https://trial.digitaltrustnetworks.com";
export const ONEPASS_SERVER_URL = "https://trial-server.digitaltrustnetworks.com/";
export const ONEPASS_ADMIN_URL = "https://trial-admin.digitaltrustnetworks.com/";
export const DEMO_SITE_ID = "SITE003";
export const DEMO_SVC_ID = "SVC003";

// develop
// export const FT_SERVER_URL = "https://onepassdev.raonsecure.co.kr:29445";
// export const ONEPASS_SERVER_URL = "https://onepassdev.raonsecure.co.kr:28445";
// export const ONEPASS_ADMIN_URL = "https://onepassdev.raonsecure.co.kr:28444";
// export const DEMO_SITE_ID = "00004";
// export const DEMO_SVC_ID = "004";

// local
// export const FT_SERVER_URL = "http://localhost:8080";
// export const ONEPASS_SERVER_URL = "https://onepassdev.raonsecure.co.kr:28445";
// export const ONEPASS_ADMIN_URL = "https://onepassdev.raonsecure.co.kr:28444";
// export const DEMO_SITE_ID = "00004";
// export const DEMO_SVC_ID = "004";
