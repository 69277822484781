import React, { useState, useRef, useEffect } from "react";

import { ModalSuccess } from "./ModalSuccess";
import {FT_SERVER_URL} from "../constant";


import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";

export const ModalForgetPassword = ({ show, close, openSubmitAlert, data }) => {

  const [title, setTitle] = useState("Forgot password");
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [successEmail, setSuccessEmail] = useState('');

  const showSuccessModal = () => {
    setIsShowSuccessModal(true);
  };
  const closeSuccessModal = () => {
    setIsShowSuccessModal(false);
  };
  // 비밀번호 재설정 이메일 전송 
  const handleRequestLink = () => {
    callRequestLink();
  };
  // input text 
  const [inputData, setInputData] = useState({
    siteId: '',
    userId: '',
    email: ''
  });
  // 입력하지 않은 div 강조
  const inputRefs = {
    siteId: useRef(null),
    svcId : useRef(null),
    userId: useRef(null),
    email : useRef(null)
  };

  useEffect(() => {
    const firstEmptyInput = Object.keys(inputData).find(
      key => inputData[key].trim() === ''
    );
    // if (firstEmptyInput) {
    //   inputRefs[firstEmptyInput].current.focus();
    // }
  }, [show]); // 모달이 열릴 때마다 실행

  const handleInputChange = (e) => {
    const {name,value} = e.target;

    setInputData({
      ...inputData,
      [name]:value
    });

    // 입력 값이 비어 있는 경우 해당 필드에 대한 에러 메시지를 설정
    if (value.trim() === '') {
     e.target.closest(".input-item").classList.add("error");
    } else {
      // 입력 값이 비어 있지 않은 경우 해당 필드에 대한 에러 메시지를 제거
      e.target.closest(".input-item").classList.remove("error");
    }
  }

  const callRequestLink = () => {
    // 비밀번호 재설정 링크 전송을 위한 API 호출
       fetch(
        FT_SERVER_URL + '/freetrial/user/password/reset/link/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ siteId: inputRefs.siteId.current.value, svcId: inputRefs.svcId.current.value, userId: inputRefs.userId.current.value, email: inputRefs.userId.current.value }) 
      })
        .then(response => {
          if (!response.ok) {
            openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
          }
          console.log(response);
          return response.json();
        })
        .then(data => {
          if(data.resultCode === "000") {
            openSubmitAlert("SUCCESS", "SEND MAIL SUCCESS");
            setSuccessEmail(data.userId);
            close();
          } else {
            openSubmitAlert("SEND MAIL FAIL", "[" + data.resultCode + "] " + data.resultMsg);
          }
        })
        .catch(error => {
          openSubmitAlert("SEND MAIL FAIL", error.toString());
          console.log('Error Fetching data: ', error)
        });
  }

  return (
    <>
      <div id="DialogForgetPassword" className={`modal ${show ? "open" : ""}`}>
        {/* <div id="DialogForgetPassword" className="modal open"> */}
        <div className="modal-dialog">
          <button className="gnb gnb-close pc-only" onClick={close}>
            <img src={GnbClose} alt="닫기" />
          </button>

          <button className="gnb gnb-close mo-only" onClick={close}>
            <img src={GnbCloseWhite} alt="닫기" />
          </button>

          <div className="modal-dialog-inner">
            <div className="modal-header">
              <h5>Forgot password</h5>
            </div>

            <span className="line"></span>

            <div className="modal-body">
              <div className="text-group">
                <p>
                  Enter your account's verified Email address and we will send
                  you a password reset link.
                </p>
              </div>

              <div className="input-group">
                <div className="input-item">
                  <div className="input">
                    <label>Site ID</label>
                    <input ref={inputRefs.siteId} type="text" placeholder="Enter Site ID" name="siteId" value={data.siteId} readOnly/>
                    <span className="focus-border"></span>
                  </div>
                </div>

                <div className="input-item">
                  <div className="input">
                    <label>Service ID</label>
                    <input ref={inputRefs.svcId} type="text" placeholder="Enter Service ID" name="svcId" value={data.svcId} readOnly/>
                    <span className="focus-border"></span>
                  </div>
                </div>

                <div className="input-item">
                  <div className="input">
                    <label>User ID</label>
                    <input ref={inputRefs.userId} type="text" placeholder="Enter User ID" name="userId" value={data.userId} readOnly/>
                    <span className="focus-border"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="button-group">
                <button
                  className="btn-lg btn-primary-fill"
                  onClick={handleRequestLink}
                >
                  Request reset link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalSuccess
        title={title}
        email={successEmail}
        show={isShowSuccessModal}
        close={closeSuccessModal}
      />
    </>
  );
};
