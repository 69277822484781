import React, {useState,useContext} from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconCheck from "../assets/images/popup/img-icon-check.svg";

import { OnepassFido2 } from "../js/OnepassFido2"

export const ModalAddPasskeyAuth = ({ show, close, openSubmitAlert }) => {
    //input text
    const [inputData, setInputData] = useState({
      nickname: '',
    });
    const [isAddPasskey, setIsAddPasskey] = useState(true);
    const [errorMessage, setErrorMessage] = useState('Nickname cannot be empty');
  

    const handleInputChange = (e) => {
      const {name,value} = e.target;
  
      setInputData({
        ...inputData,
        [name]:value
      });

      if (value.trim() === "") {
        e.target.closest(".input-item").classList.add("error");
      } else {
        // 입력 값이 비어 있지 않은 경우 해당 필드에 대한 에러 메시지를 제거
        e.target.closest(".input-item").classList.remove("error");
      }
    }

    const addPasskey = () => {
      if(isAddPasskey) {
        register();
      } else {
        close();
        window.location.reload();
      }
    }

    const register = () => {
      const sessionMember = JSON.parse(sessionStorage.getItem("member"));
      if(sessionMember) {
        // requestRegistration, 
        let reqParams = {};
        reqParams.op = "reg";

        reqParams.siteId = sessionMember.siteId;
        reqParams.svcId = sessionMember.svcId;

        reqParams.userNm = sessionMember.userId;
        reqParams.authnrAttachment = "";
        reqParams.credentialAlias = inputData.nickname;

        let result = OnepassFido2.requestService(reqParams, regCallBackFunction);
        console.log(result);
      }
    };

    function regCallBackFunction(result){
      if ("1200" == result.statusCode) {
        setIsAddPasskey(false);
      } else {
        openSubmitAlert("Failed to Add new Passkey", "[" + result.statusCode + "] \n\n" + result.description);
      }
    }
  
  return (
    <div id="DialogAddAuth" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={close}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={close}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>Add new Passkey Authenticator</h5>
          </div>

          <span className="line"></span>

          {isAddPasskey ? (
            <div className="modal-body">
              <div className="text-group">
                <p>Create a nickname for the new Authenticator</p>
              </div>

              <div className="input-group">
                <div className="input-item">
                  <div className="input">
                    <input type="text" placeholder="Enter nickname" name="nickname" value={inputData.nickname} onChange={handleInputChange}/>
                    <span className="focus-border"></span>
                  </div>
                  <span className="error-message">
                    {errorMessage}
                    
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-body">
              <div className="img-item">
              <img
                src={ImgIconCheck}
                alt="스텝이미지05"
                className="step-img"
              />
            </div>
              <div className="text-group">
                <p>Registration of the new Passkey authentication device has been completed.</p>
              </div>
            </div>
          )}

          <div className="modal-footer">
            <div className="button-group">
              <button className="btn-lg btn-primary-fill"
              disabled={Object.values(inputData).some(val => val.trim() === '')}
              onClick={addPasskey}>
                Add new Authenticator
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
