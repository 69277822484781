import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const DepthMenu = ({
  items,
  params,
  close,
  handleItemClick,
}) => {
  return (
    
      <div id="DepthMenu">
        <ul className="depth1-menu">
          {items.map((main, idx) => (
            <li
              key={idx}
              className={main.id.toString() === params.id ? "" : ""}
              onClick={() => handleItemClick(main)}
            >
              <p>{main.name}</p>
                {}
              </li>
            ))}
          </ul>
      </div>
    );
};

