import React from "react";

export const Footer = ({ isMobile, showPrivacy, showTerms }) => {
  return (
    <>
      {isMobile ? (
        <footer className="mo mo-only">
          <div className="footer-container">
            <div className="brand-title">
              <p>Digital Trust Networks Inc.</p>
            </div>

            <ul>
              <li onClick={showTerms}>Terms of Service</li>
              <li onClick={showPrivacy}>Privacy Policy</li>
              {/* <li>Open-source License</li> */}
            </ul>

            <div className="text-item">
              <span>
                2350 Mission College Boulevard, Suite 380
                <br className="mo-only" />
                Santa Clara, CA 95054 USA
              </span>

              <span>© Digital Trust Networks Inc. All rights reserved</span>
            </div>
          </div>
        </footer>
      ) : (
        <footer className="pc pc-only">
          <div className="footer-container">
            <div className="footer-left">
              <div className="brand-title">
                <p>Digital Trust Networks Inc.</p>
              </div>

              <div className="text-item">
                <span>
                  2350 Mission College Boulevard, Suite 380 Santa Clara, CA
                  95054 USA
                </span>
                <span>© Digital Trust Networks Inc. All rights reserved</span>
              </div>
            </div>

            <div className="footer-right">
              <ul>
                <li onClick={showTerms}>Terms of Service</li>
                <li onClick={showPrivacy}>Privacy Policy</li>
                {/* <li>Open-source License</li> */}
              </ul>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
