///////////////////////////////////////////////////////////////////////////////
// OnepassFido2Const.js
// =========
// 
//
///////////////////////////////////////////////////////////////////////////////
// onepass fido2 Constants and Result Code Description
//
// History:
//          1.00: First public release.
//
// AUTHOR: jkmoon(jkmoon@raonsecure.com)
// CREATED: 2018-10-18
//
// Copyright 2018. jkmoon
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////
// OnePass Constants
///////////////////////////////////////////////////////////////////////////////////////////
// Onepass Server url


    const ONEPASS_FIDO2_SERVER_ADDR								= "https://onepassdev.raonsecure.co.kr:28445";
    
    const REQUEST_REG_REQ_URL 									= '/opf2/rp/getMakeCredential.do';
    const REQUEST_REG_RESP_URL 									= '/opf2/rp/sendMakeCredential.do';	
    const REQUEST_AUTH_REQ_URL 									= '/opf2/rp/getGetAssertion.do';
    const REQUEST_AUTH_RESP_URL 								= '/opf2/rp/sendGetAssertion.do';	
    const REQUEST_DEREG_REQ_URL 								= '/opf2/rp/getDecommission.do';
    const REQUEST_DEREG_RESP_URL 								= '/opf2/rp/sendDecommission.do'; 	
    
    // Onepass Server Service Reqeust Type
    const ONEPASS_BIZ_REQ_TYPE_REG								= "reg";
    const ONEPASS_BIZ_REQ_TYPE_AUTH								= "auth";
    const ONEPASS_BIZ_REQ_TYPE_DEREG							= "dereg";
    
    //언어코드 [ 미국 :EN / 일본 :JA / 한국 : KO / 중국 : ZH  / 스페인 : ES ]
    const ONEPASS_LANGUAGE_CODE									= "KO";
    
    
    
    ///////////////////////////////////////////////////////////////////////////////////////////
    // OnePass Result Code Description
    ///////////////////////////////////////////////////////////////////////////////////////////
    const ONEPASS_SUCCESS_CODE 									= "000";
    const ONEPASS_ERROR_INTERNAL								= "901";
    const ONEPASS_FIDO_SUCCESS_CODE								= "1200";
    
    
    ///////////////////////////////////////////////////////////////////////////////////////////
    // OnePass Protocol Family Type
    ///////////////////////////////////////////////////////////////////////////////////////////
    const ONEPASS_PROTOCOL_FAMILY_TYPE_UAF                      = "uaf";
    const ONEPASS_PROTOCOL_FAMILY_TYPE_FIDO2                    = "fido2";
    
    
    ///////////////////////////////////////////////////////////////////////////////////////////
    // OnePass log level
    ///////////////////////////////////////////////////////////////////////////////////////////
    // debug, info, error(default:debug)
    const ONEPASS_FIDO2_LOG_LEVEL 								= "debug";

    


export {ONEPASS_FIDO2_LOG_LEVEL,ONEPASS_FIDO2_SERVER_ADDR,
    REQUEST_REG_REQ_URL,REQUEST_REG_RESP_URL,REQUEST_AUTH_REQ_URL,REQUEST_AUTH_RESP_URL,
    ONEPASS_FIDO_SUCCESS_CODE,ONEPASS_ERROR_INTERNAL,REQUEST_DEREG_REQ_URL,REQUEST_DEREG_RESP_URL,
    ONEPASS_BIZ_REQ_TYPE_REG,ONEPASS_BIZ_REQ_TYPE_AUTH,ONEPASS_BIZ_REQ_TYPE_DEREG,ONEPASS_LANGUAGE_CODE,
    ONEPASS_PROTOCOL_FAMILY_TYPE_UAF,ONEPASS_PROTOCOL_FAMILY_TYPE_FIDO2,ONEPASS_SUCCESS_CODE,
}