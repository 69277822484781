export const REQEST_DATA_TABLE_COLUMN = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  export const RESPONSE_DATA_TABLE_COLUMN = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  export const Table_4_2_1_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'requestServiceRegist',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'bizReqType',
      type: 'String',
      size: '5(max)',
      mo: 'M',
      definition: 'app / push / qr',
      description: 'Request type (APP/PC)',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'svcTrId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Transaction ID',
      description: 'Transaction ID created by service server',
    },
    {
      key: '4',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
      key: '5',
      nodeDepth: '1',
      field: 'svcId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Identifier',
      description: 'Issued service identifier',
    },
    {
        key: '6',
        nodeDepth: '1',
        field: 'loginId',
        type: 'String',
        size: '256(max)',
        mo: 'M',
        definition: 'User identifier',
        description: 'User identifier in the site',
      },
  ];


  export const Table_4_2_1_4 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'resultCode',
      type: 'String',
      size: '3',
      definition: 'Result code',
      description: 'Result code of request process',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'resultMsg',
      type: 'String',
      size: '128(max)',
      definition: 'Result message',
      description: 'Result message of request process',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'resultData',
      definition: 'Result data',
      description: 'Result data of request process',
    },
    {
      key: '4',
      nodeDepth: '2',
      field: 'trId',
      type: 'String',
      size: '20',
      definition: 'Transaction ID',
      description: 'Transaction ID created by the OnePass server',
    },
    {
      key: '5',
      nodeDepth: '2',
      field: 'pushResult',
      type: 'String',
      size: '1',
      definition: '0:Unsend / 1:Send',
      description: 'PUSH Send status',
    },
    {
        key: '6',
        nodeDepth: '2',
        field: 'qrImageUrl',
        type: 'String',
        size: '256(max)',
        definition: 'QR URL',
        description: 'If not receiving push',
    },
    {
        key: '7',
        nodeDepth: '2',
        field: 'qrImage',
        type: 'String',
        definition: 'QR IMAGE',
        description: 'If not receiving push',
    },
    {
        key: '8',
        nodeDepth: '2',
        field: 'expireTime',
        type: 'String',
        size: '10(max)',
        definition: 'Expiration time',
        description: 'Transaction expiration time(sec)',
    },
  ];

  export const Table_4_2_2_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'requestServiceAuth',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'bizReqType',
      type: 'String',
      size: '5(max)',
      mo: 'M',
      definition: 'app / push / qr',
      description: 'Request type (APP/PC)',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'svcTrId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Transaction ID',
      description: 'Transaction ID created by service server',
    },
    {
      key: '4',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
      key: '5',
      nodeDepth: '1',
      field: 'svcId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Identifier',
      description: 'Issued service identifier',
    },
    {
        key: '6',
        nodeDepth: '1',
        field: 'loginId',
        type: 'String',
        size: '256(max)',
        mo: 'M',
        definition: 'User identifier',
        description: 'User identifier in the site',
      },
  ];

  export const Table_4_2_3_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'requestServiceRelease',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'bizReqType',
      type: 'String',
      size: '5(max)',
      mo: 'M',
      definition: 'app / push / qr',
      description: 'Request type (APP/PC)',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'svcTrId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Transaction ID',
      description: 'Transaction ID created by service server',
    },
    {
      key: '4',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
      key: '5',
      nodeDepth: '1',
      field: 'svcId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Identifier',
      description: 'Issued service identifier',
    },
    {
        key: '6',
        nodeDepth: '1',
        field: 'loginId',
        type: 'String',
        size: '256(max)',
        mo: 'M',
        definition: 'User identifier',
        description: 'User identifier in the site',
      },
  ];

  export const Table_4_2_4_2 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'trResultConfirm',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'svcTrId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Transaction ID',
      description: 'Transaction ID created by service server',
    },
    {
        key: '3',
        nodeDepth: '1',
        field: 'protocolFamily',
        type: 'String',
        size: '5(max)',
        mo: 'O',
        definition: 'uaf / fido2',
        description: 'FIDO Protocol type (default : uaf)',
      },
  ];

  export const Table_4_2_4_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'resultCode',
      type: 'String',
      size: '3',
      definition: 'Result code',
      description: 'Result code of request process',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'resultMsg',
      type: 'String',
      size: '128(max)',
      definition: 'Result message',
      description: 'Result message of request process',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'resultData',
      definition: 'Result data',
      description: 'Result data of request process',
    },
    {
      key: '4',
      nodeDepth: '2',
      field: 'trStatus',
      type: 'String',
      size: '1',
      definition: 'Transaction status',
      description: '“0”: Failed\
                    “1”: Succeed\
                    “2”: Session Timeout\
                    "3": Transaction Pending (APP is not launched)\
                    “8”: Under Transaction\
                    “9”: No Transaction\
                    ',
    },
    {
      key: '5',
      nodeDepth: '2',
      field: 'trStatusMsg',
      type: 'String',
      size: '128(max)',
      definition: 'Transaction status message',
    },
    {
        key: '6',
        nodeDepth: '2',
        field: 'errSatusCode',
        type: 'String',
        size: '4',
        definition: 'Transaction result code',
        description: 'Error code regarding transaction failure',
    },
    {
        key: '7',
        nodeDepth: '2',
        field: 'errSatusMsg',
        type: 'String',
        sixe: '128(max)',
        definition: 'Transaction result message',
        description: 'Error message regarding transaction failure',
    },
    {
        key: '8',
        nodeDepth: '2',
        field: 'loginId',
        type: 'String',
        size: '256(max)',
        definition: 'User identifier in the site',
    },
    {
        key: '9',
        nodeDepth: '2',
        field: 'credentialHashId',
        type: 'String',
        size: '100 (max)',
        definition: 'CREDENTIAL hash ID of authenticator',
    },
    {
        key: '10',
        nodeDepth: '2',
        field: 'credentialAlias',
        type: 'String',
        size: '100 (max)',
        definition: 'CREDENTIAL nickname of authenticator',
    },
  ];

  export const Table_4_2_5_2 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'allowedAuthnr',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
        key: '3',
        nodeDepth: '1',
        field: 'svcId',
        type: 'String',
        size: '20',
        mo: 'M',
        definition: 'Service identifier',
        description: 'Issued service identifier',
      },
  ];

  export const Table_4_2_5_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'resultCode',
      type: 'String',
      size: '3',
      definition: 'Result code',
      description: 'Result code of request process',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'resultMsg',
      type: 'String',
      size: '128(max)',
      definition: 'Result message',
      description: 'Result message of request process',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'resultData',
      definition: 'Result data',
      description: 'Result data of request process',
    },
    {
      key: '4',
      nodeDepth: '2',
      field: 'aaidAllowList',
      type: 'Array',
      definition: 'Allowed AAID list',
    },
    {
      key: '5',
      nodeDepth: '3',
      field: 'aaid',
      type: 'String',
      size: '9',
      definition: 'AAID',
    },
    {
        key: '6',
        nodeDepth: '3',
        field: 'verificationType',
        type: 'String',
        size: '5',
        definition: 'Verification type',
        description: 'Authenticator type code',
    },
    {
        key: '7',
        nodeDepth: '3',
        field: 'verificationNm',
        type: 'String',
        sixe: '32(max)',
        definition: 'Verification type name',
    },
    {
        key: '8',
        nodeDepth: '3',
        field: 'vendorNm',
        type: 'String',
        size: '32(max)',
        definition: 'Vendor name',
    },
    {
        key: '9',
        nodeDepth: '3',
        field: 'vendorId',
        type: 'String',
        size: '4',
        definition: 'Vendor ID',
    },
  ];

  export const Table_4_2_6_2 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'checkRegisteredStatus',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
        key: '3',
        nodeDepth: '1',
        field: 'svcId',
        type: 'String',
        size: '20',
        mo: 'M',
        definition: 'Service identifier',
        description: 'Issued service identifier',
    },
    {
        key: '4',
        nodeDepth: '1',
        field: 'loginId',
        type: 'String',
        size: '256(max)',
        mo: 'M',
        definition: 'User identifier',
        description: 'User identifier in the site',
    },
    {
        key: '5',
        nodeDepth: '1',
        field: 'version',
        type: 'String',
        size: '1',
        mo: 'M',
        definition: 'Version information',
    },
    {
        key: '6',
        nodeDepth: '1',
        field: 'rpId',
        type: 'String',
        size: '256',
        mo: 'O',
        definition: 'RP domain information',
        description: 'Required if it is ALL/FIDO2 request',
    },
    {
        key: '7',
        nodeDepth: '1',
        field: 'protocolFamily',
        type: 'String',
        size: '5(max)',
        mo: 'O',
        definition: 'all / uaf / fido2',
        description: 'FIDO Protocol type (default : uaf)',
    },
  ];

  export const Table_4_2_6_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'resultCode',
      type: 'String',
      size: '3',
      definition: 'Result code',
      description: 'Result code of request process',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'resultMsg',
      type: 'String',
      size: '128(max)',
      definition: 'Result message',
      description: 'Result message of request process',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'resultData',
      definition: 'Result data',
      description: 'Result data of request process',
    },
    {
      key: '4',
      nodeDepth: '2',
      field: 'regYn',
      type: 'String',
      size: '1',
      definition: 'Registration status',
      description: 'Y : Registered, N : Not Registered',
    },
    {
      key: '5',
      nodeDepth: '2',
      field: 'registeredProtocolFamily',
      type: 'String',
      size: '128',
      definition: 'Registration status message',
      description: '1: uaf, 2: fido2',
    },
    {
        key: '6',
        nodeDepth: '2',
        field: 'aaidList',
        type: 'Array',
        definition: '(Optional) aaid list',
        description: 'Aaid list',
    },
    {
        key: '7',
        nodeDepth: '3',
        field: 'accepted',
        type: 'Array',
        definition: 'UAF allowed authenticator list',
        description: 'Allowed authenticator list',
    },
    {
        key: '8',
        nodeDepth: '4',
        field: 'aaid',
        type: 'String',
        size: '9',
        definition: 'AAID',
    },
    {
        key: '9',
        nodeDepth: '4',
        field: 'verificationType',
        type: 'String',
        size: '5',
        definition: 'Verification type',
        description: 'Authenticator type code',
    },
    {
        key: '10',
        nodeDepth: '4',
        field: 'verificationNm',
        type: 'String',
        sixe: '32(max)',
        definition: 'Verification type name',
    },
    {
        key: '11',
        nodeDepth: '4',
        field: 'vendorNm',
        type: 'String',
        size: '32(max)',
        definition: 'Vendor name',
    },
    {
        key: '12',
        nodeDepth: '4',
        field: 'vendorId',
        type: 'String',
        size: '4',
        definition: 'Vendor ID',
    },
    {
        key: '13',
        nodeDepth: '3',
        field: 'registered',
        type: 'Array',
        definition: 'Registered authenticator list',
        description: 'Registered authenticator list',
    },
    {
        key: '14',
        nodeDepth: '4',
        field: 'aaid',
        type: 'String',
        size: '9',
        definition: 'AAID',
    },
    {
        key: '15',
        nodeDepth: '4',
        field: 'verificationType',
        type: 'String',
        size: '5',
        definition: 'Verification type',
        description: 'Authenticator type code',
    },
    {
        key: '16',
        nodeDepth: '4',
        field: 'verificationNm',
        type: 'String',
        sixe: '32(max)',
        definition: 'Verification type name',
    },
    {
        key: '17',
        nodeDepth: '4',
        field: 'vendorNm',
        type: 'String',
        size: '32(max)',
        definition: 'Vendor name',
    },
    {
        key: '18',
        nodeDepth: '4',
        field: 'vendorId',
        type: 'String',
        size: '4',
        definition: 'Vendor ID',
    },
    {
        key: '19',
        nodeDepth: '4',
        field: 'keyId',
        type: 'String',
        size: '100',
        definition: 'Authenticator KEY ID',
    },
    {
        key: '20',
        nodeDepth: '2',
        field: 'aaguidList',
        type: 'Array',
        definition: 'aaguidList list',
        description: 'aaguidList list',
    },
    {
        key: '21',
        nodeDepth: '3',
        field: 'accepted',
        type: 'Array',
        definition: 'FIDO2 allowed authenticator list',
        description: 'Allowed authenticator list',
    },
    {
        key: '22',
        nodeDepth: '4',
        field: 'aaguid',
        type: 'String',
        size: '36',
        definition: 'AAGUID',
    },
    {
        key: '23',
        nodeDepth: '4',
        field: 'description',
        type: 'String',
        size: '400',
        definition: 'Description about authenticator',
    },
    {
        key: '24',
        nodeDepth: '4',
        field: 'verificationType',
        type: 'String',
        size: '5',
        definition: 'Verification type',
        description: 'Authenticator type code',
    },
    {
        key: '25',
        nodeDepth: '4',
        field: 'verificationNm',
        type: 'String',
        sixe: '32(max)',
        definition: 'Verification type name',
    },
    {
        key: '26',
        nodeDepth: '3',
        field: 'registered',
        type: 'Array',
        definition: 'Registered authenticator list',
        description: 'Registered authenticator list',
    },
    {
        key: '27',
        nodeDepth: '4',
        field: 'aaguid',
        type: 'String',
        size: '36',
        definition: 'AAGUID',
    },
    {
        key: '28',
        nodeDepth: '4',
        field: 'description',
        type: 'String',
        size: '400',
        definition: 'Description about authenticator',
    },
    {
        key: '29',
        nodeDepth: '4',
        field: 'credentialId',
        type: 'String',
        size: '100',
        definition: 'Authenticator CREDENTIAL ID',
    },
    {
        key: '30',
        nodeDepth: '4',
        field: 'credentialAlias',
        type: 'String',
        size: '100',
        definition: 'Authenticator CREDENTIAL nickname',
    },
    {
        key: '31',
        nodeDepth: '4',
        field: 'verificationType',
        type: 'String',
        size: '5',
        definition: 'Verification type',
        description: 'Authenticator type code',
    },
    {
        key: '32',
        nodeDepth: '4',
        field: 'verificationNm',
        type: 'String',
        sixe: '32(max)',
        definition: 'Verification type name',
    },
    {
        key: '33',
        nodeDepth: '4',
        field: 'regDt',
        type: 'String',
        size: '14',
        definition: 'Registration date',
    },
  ];