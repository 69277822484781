import React, { createElement, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { StoreArea } from "../components/StoreArea";
import { ModalFidoAuth } from "../components/ModalFidoAuth";
import { ModalForgetPassword } from "../components/ModalForgetPassword";
import { ModalDefaultAlert } from "../components/ModalDefaultAlert";
import { OnepassFido2 } from "../js/OnepassFido2"
import { OnepassFido2Util } from "../js/OnepassFido2Util"
import { FT_SERVER_URL } from "../constant";

export const Login = ({ isMobile }) => {
  const navigate = useNavigate();

  let [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('invalid');
  const [isShowFidoAuthModal, setIsShowFidoAuthModal] = useState(false);
  const [isShowForgetPasswordModal, setIsShowForgetPasswordModal] = useState(false);
  const [isPasskeyDisabled, setIsPassKeyDisabled] = useState(true);
  const [isFidoDisabled, setIsFidoDisabled] = useState(true);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    content: ''
  });

  const handleOpenModalDefaultAlert = (title, content) => {
    setAlertState({
      isOpen: true,
      title: title,
      content: content
    });
  };

  // input text
  const [inputData, setInputData] = useState({
    siteId: "",
    svcId: "",
    userId: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputData({
      ...inputData,
      [name]: value,
    });

    // 입력 값이 비어 있는 경우 해당 필드에 대한 에러 메시지를 설정
    if (value.trim() === '') {
      e.target.closest(".input-item").classList.add("error");
    } else {
      // 입력 값이 비어 있지 않은 경우 해당 필드에 대한 에러 메시지를 제거
      e.target.closest(".input-item").classList.remove("error");
    }
  };

  const handleNext = () => {
    // siteId, svcId, userId로 사용자 조회 -> 등록된 인증장치 조회
    if(currentStep === 1){
      try {
        checkRegist();
      } catch (error) {
        console.error('Error Fetching data:', error);
        handleOpenModalDefaultAlert("FAIL", 'Error Fetching data:' + error);
      }
    } // 비밀번호 입력 후 로그인
    else if(currentStep === 2){
      try {
        login();
      } catch (error) {
        console.error('Error Fetching data:', error);
        handleOpenModalDefaultAlert("FAIL", 'Error Fetching data:' + error);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };


  // Step 1에 해당하는 API 호출(사용자 조회)
  const checkRegist = async () => {
    fetch(FT_SERVER_URL +'/freetrial/user/regist/check', {
      method : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        siteId: inputData.siteId,
        svcId: inputData.svcId,
        userId: inputData.userId
      })
    })
    .then(response=> {
      if(!response.ok) {
        handleOpenModalDefaultAlert("FAIL", `HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if(data.resultCode == '000'){
        getAuthenticatorList();
      } 
      else {
        document.querySelector("input[name='siteId']").closest(".input-item").classList.add("error");
        handleOpenModalDefaultAlert("LOGIN FAIL", "[" + data.resultCode + "] " + data.resultMsg + " \n\n Check your account!");
        return;
      }
    })
    .catch(error => {
      handleOpenModalDefaultAlert("FAIL", `error! ${error}`);
    });
  }




  // Step 2에 해당하는 API 호출(로그인)
  const login = async () => {
    fetch(FT_SERVER_URL +'/freetrial/user/login', {
      method : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        siteId: inputData.siteId,
        svcId: inputData.svcId,
        userId: inputData.userId,
        password: inputData.password
      })
    })
    .then(response=> {
      if(!response.ok) {
        handleOpenModalDefaultAlert("FAIL", `HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      // TODO : Backend에서 넘어오는 에러코드는 상수로 정리 필요
      if(data.resultCode == '000'){
        navigate("/authenticators", { state : {
          siteId: inputData.siteId,
          svcId: inputData.svcId,
          userId: inputData.userId
          }
        });
        setUserContextData();
        setCurrentStep(currentStep + 1);
      } 
      else {
        document.querySelector("input[name='password']").closest(".input-item").classList.add("error");
        handleOpenModalDefaultAlert("LOGIN FAIL", "[" + data.resultCode + "] " + data.resultMsg + " \n\n Check your account!");
        return;
      }
    })
    .catch(error => {
      handleOpenModalDefaultAlert("FAIL", `error! ${error}`);
    });
  }

  // Step 1에 해당하는 API 호출(인증장치 조회)
  async function getAuthenticatorList() {
    fetch(FT_SERVER_URL + '/fidoservice/api/uaf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        command : "checkRegisteredStatus2",
        siteId: inputData.siteId,
        svcId: inputData.svcId,
        loginId: inputData.userId,
        protocolFamily: "all",
        rpId: OnepassFido2Util.getRpId(document.domain),
        version: "1"
      })
    })
    .then(response=> {
      if(!response.ok) {
        handleOpenModalDefaultAlert("FAIL", `HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setAuthenticatorButton(data);
      setCurrentStep(currentStep + 1);
    })
    .catch(error => {
      handleOpenModalDefaultAlert("FAIL", `error! ${error}`);
    });
  }

  const setUserContextData = () => {
    let member = {"siteId":inputData.siteId, "svcId":inputData.svcId, "userId":inputData.userId};
    sessionStorage.setItem("member", JSON.stringify(member));
    sessionStorage.setItem("userId", inputData.userId);
  }

  const setAuthenticatorButton = (data) => {
    if(data.resultCode === '000'){
      if(data.resultData.regYn === "Y"){
        for(let i = 0; i < data.resultData.registeredProtocolFamily.length; i++){
          let protocol = data.resultData.registeredProtocolFamily[i];
          if (protocol === 'uaf'){
            setIsFidoDisabled(false);
          } else if(protocol === 'fido2'){
            setIsPassKeyDisabled(false);
          }
        }
      } else {
        setIsFidoDisabled(true);
        setIsPassKeyDisabled(true);
      }
    }
  }

  const executePasskeyAuth = () => {
    // requestAuthentication
    let reqParams = {};
    reqParams.op = "auth";

    reqParams.siteId = inputData.siteId;
    reqParams.svcId = inputData.svcId;

    reqParams.userNm = inputData.userId;
    reqParams.authnrAttachment = "";
    reqParams.credentialAlias = "";

    let result = OnepassFido2.requestService(reqParams, authCallBackFunction);
    console.log(result);
  };

  function authCallBackFunction(result) {
    if ("1200" == result.statusCode) {
      setUserContextData();
      navigate("/authenticators");
    } else {
      handleOpenModalDefaultAlert("LOGIN FAIL", "[" + result.statusCode + "] \n\n" + result.description);
    }
  }

  const showFidoAuthModal = () => {
    setIsShowFidoAuthModal(true);
  };      

  const closeFidoAuthModal = () => {
    setIsShowFidoAuthModal(false);
  };
  const showForgetPasswordModal = () => {
    setIsShowForgetPasswordModal(true);
  };
  const closeForgetPasswordModal = () => {
    setIsShowForgetPasswordModal(false);
  };

  const loginContext = [
    {
      step: 1,
      title: "OnePassFIDO Free Trial LOGIN",
      subTitle: "",
      firstInputLabel: "Site ID",
      firstInput: createElement("input", {
        type: "text",
        placeholder: "Enter Site ID",
        name: "siteId",
        value: inputData.siteId,
        onChange: handleInputChange,
      }),
      secondInputLabel: "Service ID",
      secondInput: createElement("input", {
        type: "text",
        placeholder: "Enter Service ID",
        name: "svcId",
        value: inputData.svcId,
        onChange: handleInputChange,
      }),
      thirdInputLabel: "User ID",
      thirdInput: createElement("input", {
        type: "text",
        placeholder: "Enter User ID",
        name: "userId",
        value: inputData.userId,
        onChange: handleInputChange,
      }),
      
      errorMessage: "Invalid input value",
      button: "Next",
      link: "Request a Free Trial",
    },
    {
      step: 2,
      title: "OnePassFIDO Free Trial LOGIN",
      subTitle: "Welcome "+inputData.userId+"!",
      firstInputLabel: "Password",
      firstInput: createElement("input", {
        type: "password",
        placeholder: "Enter your Password",
        name: "password",
        value: inputData.password,
        onChange: handleInputChange,
      }),
      errorMessage: "Invalid password",
      button: "LOGIN",
      loginBtnGroup: {
        firstButton: "Passkey",
        secondButton: "FIDO.10",
      },
      link: "Other accounts",
    },
  ];

  return (
    <>
      <main>
        <div id="Login">
          <div className="container">
            {loginContext.map(
              (item) =>
                item.step === currentStep && (
                  <>
                    <div className="title">
                      <h4>{item.title}</h4>
                      {isMobile ? <h5>{item?.subTitle}</h5> : null}
                    </div>

                    <div className="login-step input-group">
                      {!isMobile && item.subTitle ? (
                        <h5>{item.subTitle}</h5>
                      ) : null}

                      <div className="input-item">
                        <div className="input">
                          <label htmlFor="">{item.firstInputLabel}</label>
                          {item.firstInput}
                          <span className="focus-border"></span>
                        </div>
                        {item.step === 1 && (
                          <>
                            <span className="error-message">
                              {item.errorMessage}
                            </span>
                          </>
                        )}

                        {item.step === 2 && (
                          <>
                            <span className="error-message">
                              {item.errorMessage}
                            </span>

                            <Link
                              className="forget"
                              onClick={showForgetPasswordModal}
                            >
                              Forgot password?
                            </Link>
                          </>
                        )}
                      </div>

                      {item.secondInput && (
                        <div className="input-item">
                          <div className="input">
                            <label htmlFor="">{item.secondInputLabel}</label>
                            {item.secondInput}
                            <span className="focus-border"></span>
                          </div>
                          <span className="error-message">
                            {item.errorMessage}
                          </span>
                        </div>
                      )}

                      {item.thirdInput && (
                        <div className="input-item">
                          <div className="input">
                            <label htmlFor="">{item.thirdInputLabel}</label>
                            {item.thirdInput}
                            <span className="focus-border"></span>
                          </div>
                          <span className="error-message">
                            {item.errorMessage}
                          </span>
                        </div>
                      )}

                      {item.step === 1 && (
                        <>
                          <div className="button-group flex-col">
                            <button
                              className="btn-lg btn-primary-fill"
                              onClick={handleNext}
                              disabled={
                                inputData.siteId.trim() === "" ||
                                inputData.svcId.trim() === "" ||
                                inputData.userId.trim() === ""
                              }
                            >
                              {item.button}
                            </button>

                            <p>
                              Don't have an account?
                              <Link to="/">{item.link}</Link>
                            </p>
                          </div>
                        </>
                      )}

                      {item.step === 2 && (
                        <>
                          <div className="button-group">
                            <button
                              className="btn-lg btn-primary-line"
                              onClick={handleNext}
                              disabled={
                                inputData.password.trim() === ""
                              }
                            >
                              LOGIN
                            </button>
                          </div>

                          <div className="divider">
                            <span className="line"></span>
                            <p>OR LOGIN WITH</p>
                            <span className="line"></span>
                          </div>

                          <div className="button-group flex-row">
                            <button
                              disabled={isPasskeyDisabled}
                              className="btn-lg btn-primary-fill"
                              onClick={executePasskeyAuth}>
                              Passkey
                            </button>
                            <button
                              disabled={isFidoDisabled}
                              className="btn-lg btn-primary-fill"
                              onClick={showFidoAuthModal}
                            >
                              FIDO1.0
                            </button>
                          </div>

                          <Link to="#" onClick={() => setCurrentStep(currentStep - 1)}>{item.link}</Link>
                        </>
                      )}
                    </div>
                  </>
                )
            )}
          </div>
        </div>
      </main>

      <StoreArea
        openSubmitAlert={handleOpenModalDefaultAlert}
        isMobile={isMobile} />

      <ModalFidoAuth
        show={isShowFidoAuthModal}
        close={closeFidoAuthModal}
        data={inputData}
        isMobile={isMobile}
        openSubmitAlert={handleOpenModalDefaultAlert} />
      <ModalForgetPassword
        show={isShowForgetPasswordModal}
        close={closeForgetPasswordModal}
        openSubmitAlert={handleOpenModalDefaultAlert}
        data={inputData}
      />
      <ModalDefaultAlert
        show={alertState.isOpen}
        close={() => setAlertState({...alertState, isOpen:false})}
        title={alertState.title}
        content={alertState.content}
      />
    </>
  );
};
