import React, { useEffect, useState } from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconEmail from "../assets/images/popup/img-icon-email.svg";
import ImgIconCheck from "../assets/images/popup/img-icon-check.svg";
import $ from "jquery";

export const ModalSuccess = ({ show, close, title, email }) => {
  const [isModalContext, setIsModalContext] = useState([
    {
      imgIcon: "",
      context: "",
      userEmail: "",
      message: "",
      button: "",
    },
  ]);

  const handleGetStart = () => {
    $("#DialogSuccess").removeClass("open");
    close();
  };

  useEffect(() => {
    if (title === "OnePassFIDO Passkey" || title === "OnePassFIDO FIDO1.0") {
      setIsModalContext({
        imgIcon: ImgIconCheck,
        context: "Authenticated!",
        userEmail: false,
        message: `How was ${
          title === "OnePassFIDO Passkey"
            ? "Passkey"
            : title === "OnePassFIDO FIDO1.0"
            ? "FIDO1.0"
            : null
        }? <br/> If you want to know more...`,
        button: "Go to the Free Trial",
      });
    }
    if (title === "Forgot password") {
      setIsModalContext({
        imgIcon: ImgIconEmail,
        context: "We've sent password reset link to:",
        userEmail: email,
        message: "Check your Email.",
        button: "Close",
      });
    }
  }, [title]);

  

  return (
    <div id="DialogSuccess" className={"modal"}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={handleGetStart}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={handleGetStart}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>{title}</h5>
          </div>

          <span className="line"></span>

          <div className="modal-body">
            <div className="img-item">
              <img
                src={isModalContext.imgIcon}
                alt="스텝이미지05"
                className="step-img"
              />
            </div>

            <div className="text-group">
              <p>{isModalContext.context}</p>
            </div>

            {isModalContext.userEmail && (
              <p className="user-email">{isModalContext.userEmail}</p>
            )}

            <div className="text-group">
              <span
                dangerouslySetInnerHTML={{ __html: isModalContext.message }}
              ></span>
            </div>
          </div>

          <div className="modal-footer">
            <div className="button-group flex-col" onClick={handleGetStart}>
              <button className="btn-lg btn-primary-fill">Go to the Free Trial</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
