///////////////////////////////////////////////////////////////////////////////
// OnepassFido2ErrorCode.js
// =========
// 
//
///////////////////////////////////////////////////////////////////////////////
// onepass fido2 Constants and Result Code Description
//
// History:
//          1.00: First public release.
//
// AUTHOR: jkmoon(jkmoon@raonsecure.com)
// CREATED: 2018-11-22
//
// Copyright 2018. jkmoon
///////////////////////////////////////////////////////////////////////////////
import {ONEPASS_FIDO2_LOG_LEVEL,ONEPASS_FIDO2_SERVER_ADDR,
    REQUEST_REG_REQ_URL,REQUEST_REG_RESP_URL,REQUEST_AUTH_REQ_URL,REQUEST_AUTH_RESP_URL,
    ONEPASS_FIDO_SUCCESS_CODE,ONEPASS_ERROR_INTERNAL,REQUEST_DEREG_REQ_URL,REQUEST_DEREG_RESP_URL,
    ONEPASS_BIZ_REQ_TYPE_REG,ONEPASS_BIZ_REQ_TYPE_AUTH,ONEPASS_BIZ_REQ_TYPE_DEREG,ONEPASS_LANGUAGE_CODE,
    ONEPASS_PROTOCOL_FAMILY_TYPE_UAF,ONEPASS_PROTOCOL_FAMILY_TYPE_FIDO2,ONEPASS_SUCCESS_CODE,
}from "./OnepassFido2Const";

var OnepassFido2ErrorCode = (() => {
	var langCode = ONEPASS_LANGUAGE_CODE;
	
	const setLangCode = (reqLangCode) => {
		langCode = reqLangCode;
	};
	
	const getLangCode = () => {
		return langCode;
	};
	
	const SUCCESS_CODE = {
		code : 0
		, name : "SuccessCode"
		, onepassErrCd : "000"
		, onepassErrMsg : {
			EN : "Success"
			, KO : "성공"
			, JA : "成功"
			, ZH : "Success"
			, ES : "Success"
		}
	};

	const ONEPASS_ERROR_INVALID_PARAMS = {
		code : 901
		, name : "InvalidParams"
		, onepassErrCd : "901"
		, onepassErrMsg : {
			EN : "Invalid params error."
			, KO : "파라메터 예외가 발생했습니다."
			, JA : "パラメータ例外が発生しました。"
			, ZH : "Invalid params error."
			, ES : "Invalid params error."
		}
	};
	
	const ONEPASS_ERROR_INTERNAL = {
		code : 902
		, name : "InternalError"
		, onepassErrCd : "902"
		, onepassErrMsg : {
			EN : "Internal server error."
			, KO : "서버에서 예외가 발생했습니다."
			, JA : "サーバから例外が発生しました。"
			, ZH : "Internal server error."
			, ES : "Internal server error."
		}
	};

	const ONEPASS_FIDO_SUCCESS_CODE	 = {
		code : 1200
		, name : "FidoSuccressCode"
		, onepassErrCd : "1200"
		, onepassErrMsg : {
			EN : "Success"
			, KO : "성공"
			, JA : "成功"
			, ZH : "Success"
			, ES : "Success"
		}
	};

	const ONEPASS_ERROR_INDEXSIZEERROR = {
		code : 1
		, name : "IndexSizeError"
		, onepassErrCd : "20001"
		, onepassErrMsg : {
			EN : "Deprecated. Use RangeError instead."
			, KO : "지원하지 않습니다. 다른 에러코드를 사용하세요."
			, JA : "支援しません。他のエラーコードを使ってください。"
			, ZH : "Deprecated. Use RangeError instead."
			, ES : "Deprecated. Use RangeError instead."
		}
	};

	const ONEPASS_ERROR_DOMSTRINGSIZEERROR = {
		code : 2
		, name : "DOMStringSizeError"
		, onepassErrCd : "20002"
		, onepassErrMsg : {
			EN : "Deprecated. Use RangeError instead."
			, KO : "지원하지 않습니다. 다른 에러코드를 사용하세요."
			, JA : "支援しません。他のエラーコードを使ってください。"
			, ZH : "Deprecated. Use RangeError instead."
			, ES : "Deprecated. Use RangeError instead."
		}
	};

	const ONEPASS_ERROR_HIERARCHYREQUESTERROR = {
		code : 3
		, name : "HierarchyRequestError"
		, onepassErrCd : "20003"
		, onepassErrMsg : {
			EN : "The operation would yield an incorrect node tree."
			, KO : "이 작업을 수행하면 잘못된 노드 트리가 생성됩니다."
			, JA : "この作業を行うと正しくないノードツリーが生成されます。"
			, ZH : "The operation would yield an incorrect node tree."
			, ES : "The operation would yield an incorrect node tree."
		}
	};

	const ONEPASS_ERROR_WRONGDOCUMENTERROR = {
		code : 4
		, name : "WrongDocumentError"
		, onepassErrCd : "20004"
		, onepassErrMsg : {
			EN : "The object is in the wrong document."
			, KO : "개체가 잘못된 문서에 있습니다."
			, JA : "個体が間違った文書にあります。"
			, ZH : "The object is in the wrong document."
			, ES : "The object is in the wrong document."
		}
	};

	const ONEPASS_ERROR_INVALIDCHARACTERERROR = {
		code : 5
		, name : "InvalidCharacterError"
		, onepassErrCd : "20005"
		, onepassErrMsg : {
			EN : "The string contains invalid characters."
			, KO : "문자열에 잘못된 문자가 있습니다."
			, JA : "文字列に間違った文字があります。"
			, ZH : "The string contains invalid characters."
			, ES : "	The string contains invalid characters."
		}
	};

	const ONEPASS_ERROR_NODATAALLOWEDERROR = {
		code : 6
		, name : "NoDataAllowedError"
		, onepassErrCd : "20006"
		, onepassErrMsg : {
			EN : "Deprecated."
			, KO : "지원하지 않습니다."
			, JA : "支援しません。"
			, ZH : "Deprecated."
			, ES : "Deprecated."
		}
	};

	const ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR = {
		code : 7
		, name : "NoModificationAllowedError"
		, onepassErrCd : "20007"
		, onepassErrMsg : {
			EN : "The object can not be modified."
			, KO : "오브젝트는 수정할 수 없습니다."
			, JA : "オブジェクトは修正できません。"
			, ZH : "The object can not be modified."
			, ES : "The object can not be modified."
		}
	};

	const ONEPASS_ERROR_NOTFOUNDERROR = {
		code : 8
		, name : "NotFoundError"
		, onepassErrCd : "20008"
		, onepassErrMsg : {
			EN : "The object can not be found here."
			, KO : "개체를 여기에서 찾을 수 없습니다."
			, JA : "ここでは個体を見つかりません。"
			, ZH : "The object can not be found here."
			, ES : "The object can not be found here."
		}
	};

	const ONEPASS_ERROR_NOTSUPPORTEDERROR = {
		code : 9
		, name : "NotSupportedError"
		, onepassErrCd : "20009"
		, onepassErrMsg : {
			EN : "The operation is not supported."
			, KO : "이 작업은 지원되지 않습니다."
			, JA : "この作業が支援しません。"
			, ZH : "The operation is not supported."
			, ES : "The operation is not supported."
		}
	};

	const ONEPASS_ERROR_INUSEATTRIBUTEERROR = {
		code : 10
		, name : "InUseAttributeError"
		, onepassErrCd : "20010"
		, onepassErrMsg : {
			EN : "The attribute is in use."
			, KO : "특성이 사용 중입니다."
			, JA : "特性が使用中です。"
			, ZH : "The attribute is in use."
			, ES : "The attribute is in use."
		}
	};

	const ONEPASS_ERROR_INVALIDSTATEERROR = {
		code : 11
		, name : "InvalidStateError"
		, onepassErrCd : "20011"
		, onepassErrMsg : {
			EN : "The object is in an invalid state."
			, KO : "오브젝트가 유효하지 않은 상태입니다."
			, JA : "オブジェクトが有効ではない状態です。"
			, ZH : "The object is in an invalid state."
			, ES : "The object is in an invalid state."
		}
	};

	const ONEPASS_ERROR_SYNTAXERROR = {
		code : 12
		, name : "SyntaxError"
		, onepassErrCd : "20012"
		, onepassErrMsg : {
			EN : "	The string did not match the expected pattern."
			, KO : "문자열이 예상 된 패턴과 일치하지 않습니다."
			, JA : "文字列が予想されたパータンと一致しません。"
			, ZH : "The string did not match the expected pattern."
			, ES : "The string did not match the expected pattern."
		}
	};

	const ONEPASS_ERROR_INVALIDMODIFICATIONERROR = {
		code : 13
		, name : "InvalidModificationError"
		, onepassErrCd : "20013"
		, onepassErrMsg : {
			EN : "The object can not be modified in this way."
			, KO : "이 방법으로 객체를 수정할 수 없습니다."
			, JA : "この方法では個体を修正できません。"
			, ZH : "The object can not be modified in this way."
			, ES : "The object can not be modified in this way."
		}
	};

	const ONEPASS_ERROR_NAMESPACEERROR = {
		code : 14
		, name : "NamespaceError"
		, onepassErrCd : "20014"
		, onepassErrMsg : {
			EN : "The operation is not allowed by Namespaces in XML."
			, KO : "XML의 네임 스페이스에서는이 작업을 수행 할 수 없습니다."
			, JA : "XMLのネームスペースではこの作業行えません。"
			, ZH : "The operation is not allowed by Namespaces in XML."
			, ES : "The operation is not allowed by Namespaces in XML."
		}
	};

	const ONEPASS_ERROR_INVALIDACCESSERROR = {
		code : 15
		, name : "InvalidAccessError"
		, onepassErrCd : "20015"
		, onepassErrMsg : {
			EN : "Deprecated. Use TypeError for invalid arguments, NotSupportedError DOMException for unsupported operations, and NotAllowedError DOMException for denied requests instead."
			, KO : "지원되지 않습니다. 무효 인 인수에는 TypeError, 지원되어 있지 않은 조작에 대해서는 NotSupportedError DOMException, 거부 된 요구에 대해서는 NotAllowedError DOMException을 사용하십시오."
			, JA : "支援しません。無効な引数にはTypeError、支援されない操作に対してはNotSupportedError DOMException、拒否された要求に対してはNotAllowedError DOMExceptionを使ってください。"
			, ZH : "Deprecated. Use TypeError for invalid arguments, NotSupportedError DOMException for unsupported operations, and NotAllowedError DOMException for denied requests instead."
			, ES : "Deprecated. Use TypeError for invalid arguments, NotSupportedError DOMException for unsupported operations, and NotAllowedError DOMException for denied requests instead."
		}
	};

	const ONEPASS_ERROR_VALIDATIONERROR = {
		code : 16
		, name : "ValidationError"
		, onepassErrCd : "20016"
		, onepassErrMsg : {
			EN : "Deprecated."
			, KO : "지원하지 않습니다."
			, JA : "支援しません。"
			, ZH : "Deprecated."
			, ES : "Deprecated."
		}
	};

	const ONEPASS_ERROR_TYPEMISMATCHERROR = {
		code : 17
		, name : "TypeMismatchError"
		, onepassErrCd : "20017"
		, onepassErrMsg : {
			EN : "Deprecated. Use TypeError instead."
			, KO : "지원하지 않습니다. 다른 에러코드를 사용하세요."
			, JA : "支援しません。他のエラーコードを使ってください。"
			, ZH : "Deprecated. Use TypeError instead."
			, ES : "Deprecated. Use TypeError instead."
		}
	};

	const ONEPASS_ERROR_SECURITYERROR = {
		code : 18
		, name : "SecurityError"
		, onepassErrCd : "20018"
		, onepassErrMsg : {
			EN : "The operation is insecure."
			, KO : "이작업은 안전하지 않습니다."
			, JA : "この作業は危険です。"
			, ZH : "The operation is insecure."
			, ES : "The operation is insecure."
		}
	};

	const ONEPASS_ERROR_NETWORKERROR = {
		code : 19
		, name : "NetworkError"
		, onepassErrCd : "20019"
		, onepassErrMsg : {
			EN : "A network error occurred."
			, KO : "네트워크 에러가 발생했습니다."
			, JA : "ネットワークエラーが発生しました。"
			, ZH : "A network error occurred."
			, ES : "A network error occurred."
		}
	};

	const ONEPASS_ERROR_ABORTERROR = {
		code : 20
		, name : "AbortError"
		, onepassErrCd : "20020"
		, onepassErrMsg : {
			EN : "The operation was aborted."
			, KO : "작업이 중단되었습니다."
			, JA : "作業が中止されました。"
			, ZH : "The operation was aborted."
			, ES : "The operation was aborted."
		}
	};

	const ONEPASS_ERROR_URLMISMATCHERROR = {
		code : 21
		, name : "URLMismatchError"
		, onepassErrCd : "20021"
		, onepassErrMsg : {
			EN : "The given URL does not match another URL."
			, KO : "지정된 URL이 다른 URL과 일치하지 않습니다."
			, JA : "指定のURLが他のURLと一致しません。"
			, ZH : "The given URL does not match another URL."
			, ES : "The given URL does not match another URL."
		}
	};

	const ONEPASS_ERROR_QUOTAEXCEEDEDERROR = {
		code : 22
		, name : "QuotaExceededError"
		, onepassErrCd : "20022"
		, onepassErrMsg : {
			EN : "The quota has been exceeded."
			, KO : "할당량이 초과되었습니다."
			, JA : "割当量を超えました。"
			, ZH : "The quota has been exceeded."
			, ES : "The quota has been exceeded."
		}
	};

	const ONEPASS_ERROR_TIMEOUTERROR = {
		code : 23
		, name : "TimeoutError"
		, onepassErrCd : "20023"
		, onepassErrMsg : {
			EN : "The operation timed out."
			, KO : "이작업은 시간이 초과되었습니다."
			, JA : "この作業はタイムアウトしました。"
			, ZH : "The operation timed out."
			, ES : "The operation timed out."
		}
	};

	const ONEPASS_ERROR_INVALIDNODETYPEERROR = {
		code : 24
		, name : "InvalidNodeTypeError"
		, onepassErrCd : "20024"
		, onepassErrMsg : {
			EN : "The supplied node is incorrect or has an incorrect ancestor for this operation."
			, KO : "제공된 노드가 올바르지 않거나이 조작에 대해 잘못된 조상을가집니다."
			, JA : "このノードが不正、あるいは、この命令では不正な祖先ノードを持っています。"
			, ZH : "The supplied node is incorrect or has an incorrect ancestor for this operation."
			, ES : "The supplied node is incorrect or has an incorrect ancestor for this operation."
		}
	};

	const ONEPASS_ERROR_DATACLONEERROR = {
		code : 25
		, name : "DataCloneError"
		, onepassErrCd : "20025"
		, onepassErrMsg : {
			EN : "The object can not be cloned."
			, KO : "개체를 복제 할 수 없습니다."
			, JA : "個体が複製できません。"
			, ZH : "The object can not be cloned."
			, ES : "The object can not be cloned."
		}
	};

	const ONEPASS_ERROR_ENCODINGERROR = {
		code : 0
		, name : "EncodingError"
		, onepassErrCd : "20026"
		, onepassErrMsg : {
			EN : "The encoding operation (either encoded or decoding) failed."
			, KO : "인코딩 작업 (인코딩 또는 디코딩 중 하나)이 실패했습니다."
			, JA : "エンコーディング作業（エンコーディングまたはデコーディング）が失敗しました。"
			, ZH : "The encoding operation (either encoded or decoding) failed."
			, ES : "The encoding operation (either encoded or decoding) failed."
		}
	};

	const ONEPASS_ERROR_NOTREADABLEERROR = {
		code : 0
		, name : "NotReadableError"
		, onepassErrCd : "20027"
		, onepassErrMsg : {
			EN : "The I/O read operation failed."
			, KO : "I / O 읽기 작업이 실패했습니다."
			, JA : "I/O読み込み作業が失敗しました。"
			, ZH : "The I/O read operation failed."
			, ES : "The I/O read operation failed."
		}
	};

	const ONEPASS_ERROR_UNKNOWNERROR = {
		code : 0
		, name : "UnknownError"
		, onepassErrCd : "20028"
		, onepassErrMsg : {
			EN : "The operation failed for an unknown transient reason (e.g. out of memory)."
			, KO : "알 수없는 일시적인 원인 (예 : 메모리 부족)으로 인해 작업이 실패했습니다."
			, JA : "分からない一時的な原因（例えばメモリ不足）で作業が失敗しました。"
			, ZH : "The operation failed for an unknown transient reason (e.g. out of memory)."
			, ES : "The operation failed for an unknown transient reason (e.g. out of memory)."
		}
	};

	const ONEPASS_ERROR_CONSTRAINTERROR = {
		code : 0
		, name : "ConstraintError"
		, onepassErrCd : "20029"
		, onepassErrMsg : {
			EN : "Deprecated. Use RangeError instead."
			, KO : "지원하지 않습니다. 다른 에러코드를 사용하세요."
			, JA : "支援しません。他のエラーコードを使ってください。"
			, ZH : "Deprecated. Use RangeError instead."
			, ES : "Deprecated. Use RangeError instead."
		}
	};

	const ONEPASS_ERROR_DATAERROR = {
		code : 0
		, name : "DataError"
		, onepassErrCd : "20030"
		, onepassErrMsg : {
			EN : "Provided data is inadequate."
			, KO : "제공된 데이터가 부적절합니다."
			, JA : "提供のデータが正しくありません。"
			, ZH : "Provided data is inadequate."
			, ES : "Provided data is inadequate."
		}
	};

	const ONEPASS_ERROR_TRANSACTIONINACTIVEERROR = {
		code : 0
		, name : "TransactionInactiveError"
		, onepassErrCd : "20031"
		, onepassErrMsg : {
			EN : "A request was placed against a transaction which is currently not active, or which is finished."
			, KO : "현재 활성화되지 않았거나 완료된 트랜잭션에 대한 요청이있었습니다."
			, JA : "現在活性化されていないか完了したトランザクションに対する要請がありました。"
			, ZH : "A request was placed against a transaction which is currently not active, or which is finished."
			, ES : "A request was placed against a transaction which is currently not active, or which is finished."
		}
	};

	const ONEPASS_ERROR_READONLYERROR = {
		code : 0
		, name : "ReadOnlyError"
		, onepassErrCd : "20032"
		, onepassErrMsg : {
			EN : "The mutating operation was attempted in a readonly transaction."
			, KO : "변경된 연산은 읽기 전용 트랜잭션에서 시도되었습니다."
			, JA : "変更された演算は読み込み専用トランザクションで試みました。"
			, ZH : "The mutating operation was attempted in a readonly transaction."
			, ES : "The mutating operation was attempted in a readonly transaction."
		}
	};

	const ONEPASS_ERROR_VERSIONERROR = {
		code : 0
		, name : "VersionError"
		, onepassErrCd : "20033"
		, onepassErrMsg : {
			EN : "An attempt was made to open a database using a lower version than the existing version."
			, KO : "기존 버전보다 낮은 버전을 사용하여 데이터베이스를 열려고 시도했습니다."
			, JA : "既存のバージョンより低いバージョンを使ってデータベースを開こうと試みました。"
			, ZH : "An attempt was made to open a database using a lower version than the existing version."
			, ES : "An attempt was made to open a database using a lower version than the existing version."
		}
	};

	const ONEPASS_ERROR_OPERATIONERROR = {
		code : 0
		, name : "OperationError"
		, onepassErrCd : "20034"
		, onepassErrMsg : {
			EN : "The operation failed for an operation-specific reason."
			, KO : "작업 특정 이유로 인해 작업이 실패했습니다."
			, JA : "作業の特定の理由により作業が失敗しました。"
			, ZH : "The operation failed for an operation-specific reason."
			, ES : "The operation failed for an operation-specific reason."
		}
	};

	const ONEPASS_ERROR_NOTALLOWEDERROR = {
		code : 0
		, name : "NotAllowedError"
		, onepassErrCd : "20035"
		, onepassErrMsg : {
			EN : "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
			, KO : "사용자가 권한을 거부했기 때문에 현재 컨텍스트에서 사용자 에이전트 나 플랫폼이 요청을 허용하지 않습니다."
			, JA : "ユーザが権限を拒否したため、現在のコンテキストでユーザエージェントやプラットフォームが要請を許可しません。"
			, ZH : "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
			, ES : "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
		}
	};
	

	// get error object
	// return errorObject
	//	{
	//		code - w3c error code		
	//		, onepassErrCd - onepass error code
	//		, onepassErrMsg - error message
	//	}
	const getError = (codeNm) => {
		let errObject = {};
		let upperCodeNm = "";
		let upperLanguageCode = langCode;
		
		if( codeNm != null )
			upperCodeNm = codeNm.toUpperCase();
		
		switch(upperCodeNm) {
			case 'SUCCESS' : 
				errObject.code = SUCCESS_CODE.code;
				errObject.onepassErrCd = SUCCESS_CODE.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = SUCCESS_CODE.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = SUCCESS_CODE.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = SUCCESS_CODE.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = SUCCESS_CODE.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = SUCCESS_CODE.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = SUCCESS_CODE.onepassErrMsg.KO;
				break;
			case 'INVALIDPARAMS' :
				errObject.code = ONEPASS_ERROR_INVALID_PARAMS.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INVALID_PARAMS.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALID_PARAMS.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALID_PARAMS.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALID_PARAMS.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALID_PARAMS.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALID_PARAMS.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALID_PARAMS.onepassErrMsg.KO;
				break;
			case 'FIDOSUCCESSCODE' :
				errObject.code = ONEPASS_FIDO_SUCCESS_CODE.code;
				errObject.onepassErrCd = ONEPASS_FIDO_SUCCESS_CODE.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_FIDO_SUCCESS_CODE.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_FIDO_SUCCESS_CODE.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_FIDO_SUCCESS_CODE.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_FIDO_SUCCESS_CODE.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_FIDO_SUCCESS_CODE.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_FIDO_SUCCESS_CODE.onepassErrMsg.KO;
				break;
			case 'INTERNALERROR' :
				errObject.code = ONEPASS_ERROR_INTERNAL.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INTERNAL.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.KO;
				break;
			case 'INDEXSIZEERROR' :
				errObject.code = ONEPASS_ERROR_INDEXSIZEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INDEXSIZEERROR.onepassErrMsg.KO;
				break;
			case 'DOMSTRINGSIZEERROR' :
				errObject.code = ONEPASS_ERROR_DOMSTRINGSIZEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_DOMSTRINGSIZEERROR.onepassErrMsg.KO;
				break;
			case 'HIERARCHYREQUESTERROR' :
				errObject.code = ONEPASS_ERROR_HIERARCHYREQUESTERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_HIERARCHYREQUESTERROR.onepassErrMsg.KO;
				break;
			case 'WRONGDOCUMENTERROR' :
				errObject.code = ONEPASS_ERROR_WRONGDOCUMENTERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_WRONGDOCUMENTERROR.onepassErrMsg.KO;
				break;
			case 'INVALIDCHARACTERERROR' :
				errObject.code = ONEPASS_ERROR_INVALIDCHARACTERERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDCHARACTERERROR.onepassErrMsg.KO;
				break;
			case 'NODATAALLOWEDERROR' :
				errObject.code = ONEPASS_ERROR_NODATAALLOWEDERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_NODATAALLOWEDERROR.onepassErrMsg.KO;
				break;
			case 'NOMODIFICATIONALLOWEDERROR' :
				errObject.code = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_NOMODIFICATIONALLOWEDERROR.onepassErrMsg.KO;
				break;
			case 'NOTFOUNDERROR' :
				errObject.code = ONEPASS_ERROR_NOTFOUNDERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTFOUNDERROR.onepassErrMsg.KO;
				break;
			case 'NOTSUPPORTEDERROR' :
				errObject.code = ONEPASS_ERROR_NOTSUPPORTEDERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTSUPPORTEDERROR.onepassErrMsg.KO;
				break;
			case 'INUSEATTRIBUTEERROR' :
				errObject.code = ONEPASS_ERROR_INUSEATTRIBUTEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INUSEATTRIBUTEERROR.onepassErrMsg.KO;
				break;
			case 'INVALIDSTATEERROR' :
				errObject.code = ONEPASS_ERROR_INVALIDSTATEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDSTATEERROR.onepassErrMsg.KO;
				break;
			case 'SYNTAXERROR' :
				errObject.code = ONEPASS_ERROR_SYNTAXERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_SYNTAXERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SYNTAXERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SYNTAXERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SYNTAXERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SYNTAXERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SYNTAXERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_SYNTAXERROR.onepassErrMsg.KO;
				break;
			case 'INVALIDMODIFICATIONERROR' :
				errObject.code = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDMODIFICATIONERROR.onepassErrMsg.KO;
				break;
			case 'NAMESPACEERROR' :
				errObject.code = ONEPASS_ERROR_NAMESPACEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NAMESPACEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NAMESPACEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NAMESPACEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NAMESPACEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NAMESPACEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NAMESPACEERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_NAMESPACEERROR.onepassErrMsg.KO;
				break;
			case 'INVALIDACCESSERROR' :
				errObject.code = ONEPASS_ERROR_INVALIDACCESSERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDACCESSERROR.onepassErrMsg.KO;
				break;
			case 'VALIDATIONERROR' :
				errObject.code = ONEPASS_ERROR_VALIDATIONERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_VALIDATIONERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VALIDATIONERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VALIDATIONERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VALIDATIONERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VALIDATIONERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VALIDATIONERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_VALIDATIONERROR.onepassErrMsg.KO;
				break;
			case 'TYPEMISMATCHERROR' :
				errObject.code = ONEPASS_ERROR_TYPEMISMATCHERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_TYPEMISMATCHERROR.onepassErrMsg.KO;
				break;
			case 'SECURITYERROR' :
				errObject.code = ONEPASS_ERROR_SECURITYERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_SECURITYERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SECURITYERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SECURITYERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SECURITYERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SECURITYERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_SECURITYERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_SECURITYERROR.onepassErrMsg.KO;
				break;
			case 'NETWORKERROR' :
				errObject.code = ONEPASS_ERROR_NETWORKERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NETWORKERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NETWORKERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NETWORKERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NETWORKERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NETWORKERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NETWORKERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_NETWORKERROR.onepassErrMsg.KO;
				break;
			case 'ABORTERROR' :
				errObject.code = ONEPASS_ERROR_ABORTERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_ABORTERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ABORTERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ABORTERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ABORTERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ABORTERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ABORTERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_ABORTERROR.onepassErrMsg.KO;
				break;
			case 'URLMISMATCHERROR' :
				errObject.code = ONEPASS_ERROR_URLMISMATCHERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_URLMISMATCHERROR.onepassErrMsg.KO;
				break;
			case 'QUOTAEXCEEDEDERROR' :
				errObject.code = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_QUOTAEXCEEDEDERROR.onepassErrMsg.KO;
				break;
			case 'TIMEOUTERROR' :
				errObject.code = ONEPASS_ERROR_TIMEOUTERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_TIMEOUTERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TIMEOUTERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TIMEOUTERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TIMEOUTERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TIMEOUTERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TIMEOUTERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_TIMEOUTERROR.onepassErrMsg.KO;
				break;
			case 'INVALIDNODETYPEERROR' :
				errObject.code = ONEPASS_ERROR_INVALIDNODETYPEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_INVALIDNODETYPEERROR.onepassErrMsg.KO;
				break;
			case 'DATACLONEERROR' :
				errObject.code = ONEPASS_ERROR_DATACLONEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_DATACLONEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATACLONEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATACLONEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATACLONEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATACLONEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATACLONEERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_DATACLONEERROR.onepassErrMsg.KO;
				break;
			case 'ENCODINGERROR' :
				errObject.code = ONEPASS_ERROR_ENCODINGERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_ENCODINGERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ENCODINGERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ENCODINGERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ENCODINGERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ENCODINGERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_ENCODINGERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_ENCODINGERROR.onepassErrMsg.KO;
				break;
			case 'NOTREADABLEERROR' :
				errObject.code = ONEPASS_ERROR_NOTREADABLEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTREADABLEERROR.onepassErrMsg.KO;
				break;
			case 'UNKNOWNERROR' :
				errObject.code = ONEPASS_ERROR_UNKNOWNERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_UNKNOWNERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_UNKNOWNERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_UNKNOWNERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_UNKNOWNERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_UNKNOWNERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_UNKNOWNERROR.onepassErrMsg.ES;
				else
					errObject.onepassErrMsg = ONEPASS_ERROR_UNKNOWNERROR.onepassErrMsg.KO;
				break;
			case 'CONSTRAINTERROR' :
				errObject.code = ONEPASS_ERROR_CONSTRAINTERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_CONSTRAINTERROR.onepassErrMsg.KO;
				break;
			case 'DATAERROR' :
				errObject.code = ONEPASS_ERROR_DATAERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_DATAERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATAERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATAERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATAERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATAERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_DATAERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_DATAERROR.onepassErrMsg.KO;
				break;
			case 'TRANSACTIONINACTIVEERROR' :
				errObject.code = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_TRANSACTIONINACTIVEERROR.onepassErrMsg.KO;
				break;
			case 'READONLYERROR' :
				errObject.code = ONEPASS_ERROR_READONLYERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_READONLYERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_READONLYERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_READONLYERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_READONLYERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_READONLYERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_READONLYERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_READONLYERROR.onepassErrMsg.KO;
				break;
			case 'VERSIONERROR' :
				errObject.code = ONEPASS_ERROR_VERSIONERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_VERSIONERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VERSIONERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VERSIONERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VERSIONERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VERSIONERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_VERSIONERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_VERSIONERROR.onepassErrMsg.KO;
				break;
			case 'OPERATIONERROR' :
				errObject.code = ONEPASS_ERROR_OPERATIONERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_OPERATIONERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_OPERATIONERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_OPERATIONERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_OPERATIONERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_OPERATIONERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_OPERATIONERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_OPERATIONERROR.onepassErrMsg.KO;
				break;
			case 'NOTALLOWEDERROR' :
				errObject.code = ONEPASS_ERROR_NOTALLOWEDERROR.code;
				errObject.onepassErrCd = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_NOTALLOWEDERROR.onepassErrMsg.KO;
				break;
			default :
				errObject.code = ONEPASS_ERROR_INTERNAL.code;
				errObject.onepassErrCd = ONEPASS_ERROR_INTERNAL.onepassErrCd;
				if( 'KO' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.KO;
				else if( 'EN' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.EN;
				else if( 'JA' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.JA;
				else if( 'ZH' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.ZH;
				else if( 'ES' == upperLanguageCode )
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.ES;
				else 
					errObject.onepassErrMsg = ONEPASS_ERROR_INTERNAL.onepassErrMsg.KO;
				break;
		}
		
		return errObject;
	}
	
	
	
	return {
		setLangCode : setLangCode
		, getLangCode : getLangCode
		, getError : getError
	};
})();
export default OnepassFido2ErrorCode;