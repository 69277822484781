import React, { useEffect, useState } from "react";

export const DepthContent = ({
  items,
  params,
  handleItemClick,
}) => {
  return (
    <div className="content">
      {items.map((main, idx) => (
        main.id.toString() === params.id && (
          main.children && (
              main.children.map((sub, idx) => (
                  <div className="content-item">
                    <h5>{sub.name}</h5>
                    {typeof sub.content === 'string' ? (
                        <div dangerouslySetInnerHTML={{ __html: sub.content }}></div>
                      ) : (
                        sub.content
                      )
                    }
                    {sub.children && (
                      <div> 
                        {sub.children.map((third, idx) => (
                          <div className="context">
                              <h5>{third.name}</h5>
                              {typeof third.content === 'string' ? (
                                  <div dangerouslySetInnerHTML={{ __html: third.content }}></div>
                                ) : (
                                  third.content
                                )
                              }
                              {third.children && (
                                <div> 
                                  {third.children.map((forth, idx) => (
                                    <div className="context">
                                        <h6>{forth.name}</h6>
                                        {typeof forth.content === 'string' ? (
                                            <div dangerouslySetInnerHTML={{ __html: forth.content }}></div>
                                          ) : (
                                            forth.content
                                          )
                                        }
                                        {forth.children && (
                                          <div> 
                                            {forth.children.map((fifth, idx) => (
                                              <div className="context">
                                                  <h6 className="sub">{fifth.name}</h6>
                                                  {typeof fifth.content === 'string' ? (
                                                      <div dangerouslySetInnerHTML={{ __html: fifth.content }}></div>
                                                    ) : (
                                                      fifth.content
                                                    )
                                                  }
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )
              )
            )
          )
      ))}
    </div>
  );
};
