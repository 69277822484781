export const passkey_table_1_4 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'svcId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service identifier',
      description: 'Issued service identifier',
    },
    {
        key: '3',
        nodeDepth: '1',
        field: 'svcTrId',
        type: 'String',
        size: '20',
        mo: 'M',
        definition: 'Service Transaction ID',
        description: 'Transaction ID created by service server',
    },
    {
        key: '4',
        nodeDepth: '1',
        field: 'origin',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'Origin of the RP',
        description: 'Origin',
    },
    {
        key: '5',
        nodeDepth: '1',
        field: 'user',
        type: 'Object',
        size: '-',
        mo: 'M',
        definition: 'User Information',
        description: '-',
    },
    {
        key: '6',
        nodeDepth: '2',
        field: 'name',
        type: 'String',
        size: '255(max)',
        mo: 'M',
        definition: 'User Identifier',
        description: 'User identification name used in the service',
    },
    {
        key: '7',
        nodeDepth: '2',
        field: 'displayName',
        type: 'String',
        size: '100(max)',
        mo: 'O',
        definition: 'User display name',
        description: 'User display name used in the service',
    },
    {
        key: '8',
        nodeDepth: '1',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'M',
        definition: 'RP Information',
        description: '-',
    },
    {
        key: '9',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'RP Domain',
        description: 'RP Domain',
    },
    {
        key: '10',
        nodeDepth: '1',
        field: 'reqProtocolType',
        type: 'String',
        size: '1(max)',
        mo: 'O',
        definition: 'Request Protocol Type',
        description: '1 (HTTP)',
    },
    {
        key: '11',
        nodeDepth: '1',
        field: 'authnrAttachment',
        type: 'String',
        size: '14(max)',
        mo: 'O',
        definition: 'Authenticator attachment method',
        description: 'platform, cross-platform',
    },
    {
        key: '12',
        nodeDepth: '1',
        field: 'credentialAlias',
        type: 'String',
        size: '100(max)',
        mo: 'O',
        definition: 'Authenticator credential alias',
        description: 'Name used when displaying the list of registered authenticator',
    },
  ];
  
  export const passkey_columns_1_4 = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_1_5= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'statusCode',
      type: 'String',
      size: '4',
      mo: 'M',
      definition : 'Response Code',
      description: 'Response Code',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'description',
      type: 'String',
      size: '-',
      mo: 'O',
      description: 'Failure message',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'result',
      type: 'String',
      size: '-',
      mo: 'M',
      description: 'Response Result',
    },
    {
        key: '4',
        nodeDepth: '2',
        field: 'publicKey',
        type: 'Object',
        size: '-',
        mo: 'M',
        description: 'FIDO PublicKey',
      },
      {
        key: '5',
        nodeDepth: '3',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'M',
        description: 'RP Information',
      },
      {
        key: '6',
        nodeDepth: '4',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'RP domain',
      },
      {
        key: '7',
        nodeDepth: '4',
        field: 'name',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'RP Name',
      },
      {
        key: '8',
        nodeDepth: '1',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'M',
        description: '-',
      },
      {
        key: '9',
        nodeDepth: '3',
        field: 'user',
        type: 'Object',
        size: '-',
        mo: 'M',
        description: 'User Information',
      },
      {
        key: '10',
        nodeDepth: '4',
        field: 'displayName',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'User display name used in the service',
      },
      {
        key: '11',
        nodeDepth: '4',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'User identification name used in the OnePassFIDO',
      },
      {
        key: '12',
        nodeDepth: '4',
        field: 'name',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'User identification name used in the service',
      },
      {
        key: '13',
        nodeDepth: '3',
        field: 'challenge',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'Random challenge value provided by the server',
      },
      {
        key: '14',
        nodeDepth: '3',
        field: 'pubKeyCredParams',
        type: 'Object',
        size: '-',
        mo: 'M',
        description: 'Credential Public Key Algorithm',
      },
      {
        key: '15',
        nodeDepth: '4',
        field: 'type',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'algorithm type',
      },
      {
        key: '16',
        nodeDepth: '4',
        field: 'alg',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'algorithm',
      },
      {
        key: '17',
        nodeDepth: '3',
        field: 'timeout',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'Time limit',
      },
      {
        key: '18',
        nodeDepth: '3',
        field: 'excludeCredentials',
        type: 'Object',
        size: '-',
        mo: 'O',
        description: 'Restricted registration credential ID list',
      },
      {
        key: '19',
        nodeDepth: '4',
        field: 'type',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'algorithm type',
      },
      {
        key: '20',
        nodeDepth: '4',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'credential id',
      },
      {
        key: '21',
        nodeDepth: '3',
        field: 'authenticatorSelection',
        type: 'Object',
        size: '-',
        mo: 'O',
        description: '',
      },
      {
        key: '22',
        nodeDepth: '4',
        field: 'authenticatorAttachment',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'Authenticator attachment method',
      },
      {
        key: '23',
        nodeDepth: '4',
        field: 'requireResidentKey',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'Specify whether to store the private key on the authentication device',
      },
      {
        key: '24',
        nodeDepth: '4',
        field: 'userVerification',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'Whether to perform user verification',
      },
      {
        key: '24',
        nodeDepth: '2',
        field: 'challengeToken',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'Server token for transaction verification',
      },
      {
        key: '25',
        nodeDepth: '2',
        field: 'trId',
        type: 'String',
        size: '20',
        mo: 'M',
        description: 'Transaction identifier',
      },
  ];
  
  export const passkey_columns_1_5 = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_2_2= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'origin',
      type: 'String',
      size: '100(max)',
      mo: 'M',
      definition :'Origin of the RP',
      description: 'Origin',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'user',
      type: 'Object',
      size: '-',
      mo: 'O',
      definition :'User Information',
      description: '-',
    },
    {
      key: '3',
      nodeDepth: '2',
      field: 'name',
      type: 'String',
      size: '255(max)',
      mo: 'O',
      definition :'User name',
      description: 'User identifier in the site',
    },
    {
        key: '4',
        nodeDepth: '1',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'O',
        definition :'RP Information',
        description: '-',
      },
      {
        key: '5',
        nodeDepth: '2',
        field: 'id',
        type: 'Object',
        size: '100(max)',
        mo: 'O',
        definition :'RP domain',
        description: 'RP domain',
      },
      {
        key: '6',
        nodeDepth: '1',
        field: 'credential',
        type: 'Object',
        size: '-',
        type: 'Object',
        mo: 'M',
        definition :'-',
        description: '-',
      },
      {
        key: '7',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'M',
        definition :'credential id',
        description: 'Identification value of key (credentialid)',
      },
      {
        key: '8',
        nodeDepth: '2',
        field: 'type',
        type: 'String',
        size: '-',
        mo: 'O',
        definition: 'algorithm type',
        description: 'public-key',
      },
      {
        key: '9',
        nodeDepth: '2',
        field: 'rawId',
        type: 'String',
        size: '-',
        mo: 'O',
        definition: 'rawId',
        description: 'credentialId stored in the authenticator',
      },
      {
        key: '10',
        nodeDepth: '2',
        field: 'response',
        type: 'Object',
        size: '-',
        definition :'-',
        description: '-',
      },
      {
        key: '11',
        nodeDepth: '3',
        field: 'clientDataJSON',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'client data',
        description: 'Value delivered by client for authentication',
      },
      {
        key: '12',
        nodeDepth: '3',
        field: 'attestationObject',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'Attestation Object',
        description: 'Signature value including authenticator data and attestation statement',
      },
      {
        key: '13',
        nodeDepth: '1',
        field: 'challengeToken',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'challengeToken',
        description: 'Token value for session test',
      },
      {
        key: '14',
        nodeDepth: '1',
        field: 'trId',
        type: 'String',
        size: '20',
        mo: 'O',
        definition: 'Transaction ID',
        description: 'Transaction ID generated by OnePass server',
      }
  ];
  
  export const passkey_columns_2_2 = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_2_2_res= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'statusCode',
      type: 'String',
      size: '4',
      mo: 'M',
      description: 'Response Code',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'description',
      type: 'String',
      size: '-',
      mo: 'O',
      description: 'Failure Message',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'result',
      type: 'JsonArray',
      size: '-',
      mo: 'M',
      description: '-',
    },
    {
        key: '4',
        nodeDepth: '2',
        field: 'credentialHashId',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'session key',
      },
  ];
  
  export const passkey_columns_2_2_res = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_auth= [
    {
        key: '1',
        nodeDepth: '1',
        field: 'siteId',
        type: 'String',
        size: '20',
        mo: 'M',
        definition: 'Site identifier',
        description: 'Issued site identifier',
      },
      {
        key: '2',
        nodeDepth: '1',
        field: 'svcId',
        type: 'String',
        size: '20',
        mo: 'M',
        definition: 'Service identifier',
        description: 'Issued service identifier',
      },
      {
          key: '3',
          nodeDepth: '1',
          field: 'svcTrId',
          type: 'String',
          size: '20',
          mo: 'M',
          definition: 'Service Transaction ID',
          description: 'Transaction ID created by service server',
      },
      {
          key: '4',
          nodeDepth: '1',
          field: 'origin',
          type: 'String',
          size: '100(max)',
          mo: 'M',
          definition: 'Origin of the RP URL',
          description: 'Origin',
      },
      {
          key: '5',
          nodeDepth: '1',
          field: 'user',
          type: 'Object',
          size: '-',
          mo: 'M',
          definition: 'User Information',
          description: '-',
      },
      {
          key: '6',
          nodeDepth: '2',
          field: 'name',
          type: 'String',
          size: '255(max)',
          mo: 'M',
          definition: 'User Identifier',
          description: 'User identifier in the site',
      },
      {
          key: '7',
          nodeDepth: '2',
          field: 'displayName',
          type: 'String',
          size: '100(max)',
          mo: 'O',
          definition: 'Username',
          description: 'User name in the site',
      },
      {
          key: '8',
          nodeDepth: '1',
          field: 'rp',
          type: 'Object',
          size: '-',
          mo: 'M',
          definition: 'RP Information',
          description: '-',
      },
      {
          key: '9',
          nodeDepth: '2',
          field: 'id',
          type: 'String',
          size: '100(max)',
          mo: 'M',
          definition: 'RP Domain',
          description: 'RP Domain',
      },
      {
          key: '10',
          nodeDepth: '1',
          field: 'reqProtocolType',
          type: 'String',
          size: '1(max)',
          mo: 'O',
          definition: 'Request Protocol Type',
          description: '1 (HTTP)',
      },
      {
          key: '11',
          nodeDepth: '1',
          field: 'authnrAttachment',
          type: 'String',
          size: '14(max)',
          mo: 'O',
          definition: 'Authenticator attachment method',
          description: 'platform, cross-platform',
      },
    {
        key: '4',
        nodeDepth: '2',
        field: 'credentialHashId',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'session key',
      }
  ];
  
  export const passkey_columns_auth = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
        title: 'Definition',
        dataIndex: 'definition',
        key: 'definition',
      },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_auth_res= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'statusCode',
      type: 'String',
      size: '4',
      mo: 'M',
      description: 'Response Code',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'description',
      type: 'String',
      size: '-',
      mo: 'O',
      description: 'Failure Message',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'result',
      type: 'Object',
      size: '-',
      mo: 'M',
      description: '-',
    },
    {
        key: '4',
        nodeDepth: '2',
        field: 'publicKey',
        type: 'Object',
        size: '-',
        mo: 'M',
        description: 'FIDO PublicKey',
      },
      {
        key: '5',
        nodeDepth: '3',
        field: 'challenge',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'Server-provided random challenge value',
      },
      {
        key: '6',
        nodeDepth: '3',
        field: 'timeout',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'Expiration time',
      },
      {
        key: '7',
        nodeDepth: '3',
        field: 'rpId',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'RP Domain',
      },
      {
        key: '8',
        nodeDepth: '3',
        field: 'allowCredentials',
        type: 'Object',
        size: '-',
        mo: 'O',
        description: 'Registered list',
      },
      {
        key: '9',
        nodeDepth: '4',
        field: 'type',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'algorithm type',
      },
      {
        key: '10',
        nodeDepth: '4',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'credential id',
      },
      {
        key: '11',
        nodeDepth: '3',
        field: 'userVerification',
        type: 'String',
        size: '-',
        mo: 'O',
        description: 'User authentication method',
      },
      {
        key: '12',
        nodeDepth: '2',
        field: 'challengeToken',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'challengeToken',
      },
      {
        key: '13',
        nodeDepth: '2',
        field: 'trId',
        type: 'String',
        size: '20',
        mo: 'M',
        description: 'Transaction ID',
      }
  ];
  
  export const passkey_columns_auth_res = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_auth_req_2= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'origin',
      type: 'String',
      size: '100(max)',
      mo: 'M',
      definition :'origin',
      description: 'Service Url',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'user',
      type: 'Object',
      size: '-',
      mo: 'O',
      definition :'User Information',
      description: '-',
    },
    {
      key: '3',
      nodeDepth: '2',
      field: 'name',
      type: 'String',
      size: '255(max)',
      mo: 'O',
      definition :'User Information',
      description: 'User Identifier User identifier within the site',
    },
    {
        key: '4',
        nodeDepth: '1',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'O',
        definition :'RP Information',
        description: '-',
      },
      {
        key: '5',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '100(max)',
        mo: 'O',
        definition :'RP domain',
        description: 'RP domain',
      },
      {
        key: '6',
        nodeDepth: '1',
        field: 'credential',
        type: 'Object',
        size: '-',
        type: 'Object',
        mo: 'M',
        definition :'Credential',
        description: 'Credential Information',
      },
      {
        key: '7',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'M',
        definition :'credential id',
        description: 'Credential ID Key identifier (credentialid)',
      },
      {
        key: '8',
        nodeDepth: '2',
        field: 'type',
        type: 'String',
        size: '-',
        mo: 'O',
        definition: 'algorithm type',
        description: 'public-key',
      },
      {
        key: '9',
        nodeDepth: '2',
        field: 'rawId',
        type: 'String',
        size: '-',
        mo: 'O',
        definition: 'rawId',
        description: 'credentialId store in authenticator',
      },
      {
        key: '10',
        nodeDepth: '2',
        field: 'response',
        type: 'Object',
        size: '-',
        definition :'-',
        description: '-',
      },
      {
        key: '11',
        nodeDepth: '3',
        field: 'clientDataJSON',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'client data',
        description: 'Value delivered by client for authentication',
      },
      {
        key: '12',
        nodeDepth: '3',
        field: 'authenticatorData',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'authenticator data',
        description: 'authenticatorData value delivered by authenticator',
      },
      {
        key: '13',
        nodeDepth: '3',
        field: 'signature',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'signature value',
        description: 'e-signature delivered by authenticator',
      },
      {
        key: '14',
        nodeDepth: '3',
        field: 'userHandle',
        type: 'String',
        size: '-',
        mo: 'O',
        definition: 'userHandle',
        description: 'Transaction ID generated by OnePass server',
      },
      {
        key: '15',
        nodeDepth: '1',
        field: 'challengeToken',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'challengeToken',
        description: 'Token value for session test',
      },
      {
        key: '16',
        nodeDepth: '1',
        field: 'trId',
        type: 'String',
        size: '20',
        mo: 'O',
        definition: 'transactionID',
        description: 'Transaction ID created by OnePass server',
      }
  ];

  export const passkey_columns_auth_req_2 = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_auth_res_3_2= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'statusCode',
      type: 'String',
      size: '4',
      mo: 'M',
      description: 'Response Code',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'description',
      type: 'String',
      size: '-',
      mo: 'O',
      description: 'Failure Message',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'result',
      type: 'Object',
      size: '-',
      mo: 'M',
      description: '-',
    },
    {
        key: '4',
        nodeDepth: '2',
        field: 'credentialHashId',
        type: 'String',
        size: '-',
        mo: 'M',
        description: 'session key',
      },
  ];
  
  export const passkey_columns_auth_res_3_2 = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_deReg_req = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'siteId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Site identifier',
      description: 'Issued site identifier',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'svcId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service identifier',
      description: 'Issued service identifier',
    },
    {
        key: '3',
        nodeDepth: '1',
        field: 'svcTrId',
        type: 'String',
        size: '20',
        mo: 'M',
        definition: 'Service Transaction ID',
        description: 'Transaction ID created by service server',
    },
    {
        key: '4',
        nodeDepth: '1',
        field: 'origin',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'Origin of the RP URL',
        description: 'Origin',
    },
    {
        key: '5',
        nodeDepth: '1',
        field: 'user',
        type: 'Object',
        size: '-',
        mo: 'M',
        definition: 'User Information',
        description: '-',
    },
    {
        key: '6',
        nodeDepth: '2',
        field: 'name',
        type: 'String',
        size: '255(max)',
        mo: 'M',
        definition: 'User name',
        description: 'User information in the site',
    },
    {
        key: '7',
        nodeDepth: '2',
        field: 'displayName',
        type: 'String',
        size: '100(max)',
        mo: 'O',
        definition: 'Username',
        description: 'User name in the site',
    },
    {
        key: '8',
        nodeDepth: '1',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'M',
        definition: 'RP Information',
        description: '-',
    },
    {
        key: '9',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'RP Domain',
        description: 'RP Domain',
    },
    {
        key: '10',
        nodeDepth: '1',
        field: 'reqProtocolType',
        type: 'String',
        size: '1(max)',
        mo: 'O',
        definition: 'Request Protocol Type',
        description: '1 (HTTP)',
    }
  ];
  
  export const passkey_columns_deReg_req = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const passkey_table_dereg_req_2= [
    {
      key: '1',
      nodeDepth: '1',
      field: 'origin',
      type: 'String',
      size: '100(max)',
      mo: 'M',
      definition :'origin',
      description: 'Service Url',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'user',
      type: 'Object',
      size: '-',
      mo: 'O',
      definition :'User Information',
      description: '-',
    },
    {
      key: '3',
      nodeDepth: '2',
      field: 'name',
      type: 'String',
      size: '255(max)',
      mo: 'O',
      definition :'User Information',
      description: 'User Identifier User identifier within the site',
    },
    {
        key: '4',
        nodeDepth: '1',
        field: 'rp',
        type: 'Object',
        size: '-',
        mo: 'O',
        definition :'RP Information',
        description: '-',
      },
      {
        key: '5',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '100(max)',
        mo: 'O',
        definition :'RP domain',
        description: 'RP domain',
      },
      {
        key: '6',
        nodeDepth: '1',
        field: 'credential',
        type: 'Object',
        size: '-',
        type: 'Object',
        mo: 'M',
        definition :'Credential',
        description: 'Credential Information',
      },
      {
        key: '7',
        nodeDepth: '2',
        field: 'id',
        type: 'String',
        size: '-',
        mo: 'M',
        definition :'credential id',
        description: 'Credential ID Key identifier (credentialid)',
      },
      {
        key: '8',
        nodeDepth: '2',
        field: 'response',
        type: 'Object',
        size: '-',
        mo: 'M',
        definition :'Authentication information',
        description: '-',
      },
      {
        key: '11',
        nodeDepth: '3',
        field: 'clientDataJSON',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'client data',
        description: 'Value delivered by client for authentication',
      },
      {
        key: '12',
        nodeDepth: '3',
        field: 'authenticatorData',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'authenticator data',
        description: 'AuthenticatorData transmitted from the authenticator',
      },
      {
        key: '13',
        nodeDepth: '3',
        field: 'signature',
        type: 'String',
        size: '-',
        mo: 'M',
        definition: 'signature value',
        description: 'e-signature delivered by authenticator',
      },
      {
        key: '14',
        nodeDepth: '1',
        field: 'challengeToken',
        type: 'String',
        size: '100(max)',
        mo: 'M',
        definition: 'challengeToken',
        description: 'Token value for session verification',
      },
      {
        key: '15',
        nodeDepth: '1',
        field: 'trId',
        type: 'String',
        size: '20',
        mo: 'O',
        definition: 'transactionID',
        description: 'Transaction ID generated by the OnePass server',
      }
  ];
  
  export const passkey_columns_dereg_req_2 = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];