import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { ModalPasskeySign } from "../components/ModalPasskeySign";
import { ModalFidoSign } from "../components/ModalFidoSign";
import { ModalSuccess } from "../components/ModalSuccess";
import { ModalSubmitAlert } from "../components/ModalSubmitAlert";
import { ModalDefaultAlert } from "../components/ModalDefaultAlert";
import { ModalPrivacyPolicy } from "../components/ModalPrivacyPolicy";
import { FT_SERVER_URL } from "../constant";

// IMAGES
import FidoUAF from "../assets/images/content/FIDO-UAF.png";
import Fido2 from "../assets/images/content/FIDO2.png";
import ArrowRightWhite from "../assets/images/svg/arrow-right-w.svg";
import ArrowRight from "../assets/images/svg/arrow-right.svg";
import ArrowDown from "../assets/images/svg/arrow-down.svg";
import ImgPasskeyIcon from "../assets/images/content/img-passkey-icon.png";
import ImgFidoIcon from "../assets/images/content/img-fido-icon.png";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

export const Home = ({ isMobile }) => {
  const scrollPosition = useRef(null);
  const dropdownRef = useRef(null);
  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState('Please enter a value');
  const [emailErrorMessage, setEmailErrorMessage] = useState('Invalid Email Address');
  const [isShowPasskeyModal, setIsShowPasskeyModal] = useState(false);
  const [isShowFidoModal, setIsShowFidoModal] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowSubmitModal, setIsShowSubmitModal] = useState(false);
  const [isShowDefaultModal, setIsShowDefaultModal] = useState(true);
  const [isShowPrivacyModal, setIsShowPrivacyModal] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isSelectOption, setIsSelectOption] = useState("Choose your country");
  const [isCallNextStep, setIsCallNextStep] = useState(false);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    content: '',
    isPasskey: false,
  });

  const handleOpenModalDefaultAlert = (title, content, isPasskey) => {
    setAlertState({
      isOpen: true,
      title: title,
      content: content,
      isPasskey: isPasskey
    });
  };

  const handleCloseModalDefaultAlert = () => {
    if(alertState.isPasskey){
      setIsCallNextStep(true);
    } else {
      setIsCallNextStep(false);
    }
    setAlertState({
      ...alertState,
      isOpen: false,
      isPasskey: false,
    });
  };

  // 국가코드 DB에서 데이터 불러오기
  const [countryList, setCountryList] = useState([]);
    useEffect(() => {
      axios.get(FT_SERVER_URL+'/freetrial/user/countries')
        .then(response => {
          setCountryList(response.data);
        })
        .catch(error => {
          console.error('Error fetching data : ' + error);
        });
    }, []);


  const showPasskeyModal = () => {
    setIsShowPasskeyModal(true);
  };
  const closePasskeyModal = () => {
    setIsShowPasskeyModal(false);
    showSuccessModal();
    setTitle("OnePassFIDO Passkey");
  };
  const showFidoModal = () => {
    setIsShowFidoModal(true);
  };
  const closeFidoModal = () => {
    setIsShowFidoModal(false);
    showSuccessModal();
    setTitle("OnePassFIDO FIDO1.0");
  };
  const showSuccessModal = () => {
    setIsShowSuccessModal(true);
  };
  const closeSuccessModal = () => {
    handlePositionScroll();
    setIsShowSuccessModal(false);
  };
  const showSubmitModal = () => {
    const requestData = {
      name: inputData.name,
      email: inputData.email,
      companyName: inputData.companyName,
      companySite: inputData.companySite,
      country: inputData.country,
    };
    console.log(requestData);


    // 첫 번째 API 호출
    fetch(FT_SERVER_URL + "/freetrial/user/registration/request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          handleOpenModalDefaultAlert("FAIL", `HTTP error! Status: ${response.status}`);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if(data.resultCode === "000") {
          setIsShowSubmitModal(true);
        } else {
          handleOpenModalDefaultAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
        }
      })
  };
  const closeSubmitModal = () => {
    setIsShowSubmitModal(false);
  };
  const showDefaultModal = () => {
    setIsShowDefaultModal(true);
  };
  const closeDefaultModal = () => {
    setIsShowDefaultModal(false);
  };
  const showPrivacyModal = () => {
    setIsShowPrivacyModal(true);
  };
  const closePrivacyModal = () => {
    setIsShowPrivacyModal(false);
  };

  const handleShowDropdown = (e) => {
    setIsShowDropdown(!isShowDropdown);
    e.stopPropagation();
  };

  const handleSelectOption = (e) => {
    const name = e.target.getAttribute("name");
    const selectCountryName = e.target.innerText;
    const selectedCountry = countryList.find(item => item.name === selectCountryName);
    if (selectedCountry) {
      // const selectedCountryCode = selectedCountry.code;
      setIsShowDropdown(false);
      setIsSelectOption(selectCountryName);
      setInputData({
        ...inputData,
        country: selectedCountry.code,
      });
    } else {
      console.error("selected country not found");
    }
  };
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    companyName: "",
    companySite: "",
    country: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputData({
      ...inputData,
      [name]: value,
    });
    // 입력 값이 비어 있는 경우 해당 필드에 대한 에러 메시지를 설정
    if (value.trim() === "") {
      e.target.closest(".input-item").classList.add("error");
    } else {
      // 입력 값이 비어 있지 않은 경우 해당 필드에 대한 에러 메시지를 제거
        e.target.closest(".input-item").classList.remove("error");
      }

    // 이메일 유효성 검사 정규식
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

    // 이메일 필드에서 입력된 값
    if (!emailRegex.test(inputData.email.trim())) {
      document.querySelector("input[name='email']").closest(".input-item").classList.add("error");
      setEmailErrorMessage('Invalid Email Address, Please insert it correctly');
      return;
    }
  };

  // 입력값 유효성 검사
  const submitInputRequirements =
    inputData.name &&
    inputData.email &&
    inputData.companyName &&
    inputData.companySite;

  const handlePositionScroll = () => {
    scrollPosition.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleDropdownClose = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsShowDropdown(false);
      }
      if (e.key === "Escape") {
        setIsShowDropdown(false);
      }
    };
    window.addEventListener("keydown", handleDropdownClose);
    window.addEventListener("mousedown", handleDropdownClose);
  }, []);

  return (
    <>
      <main>
        <article className="main">
          <div className="container">
            <div className="title">
              <h1>
                Try the safer and more 
                convenient OnePassFIDO right away!
              </h1>

              <div className="fido-group">
                <img src={FidoUAF} alt="FidoUAF" />
                <img src={Fido2} alt="Fido2" />
              </div>
              <div className="content">
                <div className="box-item">
                  <div className="text-group">
                    <p>Passkey Demo</p>
                    <span>
                      Authenticate with biometrics, <br />
                      PIN and pattern stored on your device.
                    </span>
                  </div>

                  <div className="button-group">
                    <button
                      className="btn-md btn-primary-fill"
                      onClick={showPasskeyModal}
                    >
                      Start Passkey
                      <i>
                        <img src={ArrowRightWhite} alt="ArrowRightWhite" />
                      </i>
                    </button>
                  </div>

                  <div className="img-group">
                    <img src={ImgPasskeyIcon} alt="ImgPasskeyIcon" />
                  </div>
                </div>

                <div className="box-item">
                  <div className="text-group">
                    <p>FIDO1.0 Demo</p>
                    <span>
                      Use the app to authenticate with
                      <br />
                      biometrics, PIN and pattern.
                    </span>
                  </div>

                  <div className="button-group">
                    <button
                      className={`btn-md ${
                        isMobile ? "btn-primary-fill" : "btn-primary-line"
                      }`}
                      onClick={showFidoModal}
                    >
                      Start FIDO1.0
                      <i>
                        <img
                          src={isMobile ? ArrowRightWhite : ArrowRight}
                          alt="ArrowRight"
                        />
                      </i>
                    </button>
                  </div>

                  <div className="img-group">
                    <img src={ImgFidoIcon} alt="ImgFidoIcon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="main-bottom"></article>
        <article className="try-for-free" ref={scrollPosition}>
          <div className="container">
            <div className="title">
              <h1>Free Trial</h1>

              <div className="sub-title">
                <h3>
                  Sign up for a free trial and gain access to OnePassFIDO.<br/>
                  We’ll create an account for you.
                </h3>
                <span>
                  Fill out the form to sign up for a free trial.
                </span>
              </div>
              <div style={{textAlign: 'left'}} className="sub-title">
                <span>
                  Get access to... <br/>
                  - Policy management page<br />
                  - Documentations<br />
                  - Guidelines for OnePassFIDO<br />
                </span>
              </div>
            </div>

            <div className="input-group">
              <div className="input-item">
                <div className="input">
                  <label htmlFor="">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    name="name"
                    value={inputData.name}
                    onChange={handleInputChange}
                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{errorMessage}</span>
              </div>

              <div className="input-item">
                <div className="input">
                  <label htmlFor="">
                    Business Email <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your business Email address"
                    name="email"
                    value={inputData.email}
                    onChange={handleInputChange}
                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{emailErrorMessage}</span>
              </div>

              <div className="input-item">
                <div className="input">
                  <label htmlFor="">
                    Company name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your company name"
                    value={inputData.companyName}
                    name="companyName"
                    onChange={handleInputChange}
                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{errorMessage}</span>
              </div>

              <div className="input-item">
                <div className="input">
                  <label htmlFor="">
                    Company website <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your company website address"
                    name="companySite"
                    value={inputData.companySite}
                    onChange={handleInputChange}
                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{errorMessage}</span>
              </div>

              <div className="dropdown-item">
                <select name="" id="select"></select>

                <label htmlFor="select">
                  <div
                    className={`dropdown ${isShowDropdown ? "active" : ""}`}
                    ref={dropdownRef}
                  >
                    <div
                      className="dropdown-select"
                      onClick={handleShowDropdown}
                    >
                      <p>{isSelectOption}</p>

                      <i className="icon arrow-down size-24">
                        <img src={ArrowDown} alt="ArrowDown" />
                      </i>

                      <span className="focus-border"></span>
                    </div>
                    <ul className="dropdown-option">
                      {countryList.map((item) => (
                          <option onClick={handleSelectOption} key={item.code} name={item.code} value={inputData.country}>{item.name}</option>
                        ))}
                    </ul>
                  </div>
                </label>
              </div>

              <p className="info-box">
                Nothing matters more to us than your privacy and security. We'll
                never sell or mishandle your data, and you can unsubscribe at
                any time. Learn more about our commitment to protecting your
                privacy &nbsp;
                <Link onClick={showPrivacyModal}>here.</Link>
              </p>
            </div>

            <div className="button-group">
              <button
                className="btn-lg btn-primary-line"
                onClick={showSubmitModal}
                disabled={!submitInputRequirements}
              >
                Submit
              </button>
            </div>
          </div>
        </article>
      </main>

      <ModalPasskeySign
        show={isShowPasskeyModal}
        close={closePasskeyModal}
        openSubmitAlert={handleOpenModalDefaultAlert}
        isCallNextStep={isCallNextStep}
      />
      <ModalFidoSign
        show={isShowFidoModal}
        close={closeFidoModal}
        isMobile={isMobile}
        openSubmitAlert={handleOpenModalDefaultAlert}
      />
      <ModalSuccess
        title={title}
        show={isShowSuccessModal}
        close={closeSuccessModal}
      />
      <ModalPrivacyPolicy show={isShowPrivacyModal} close={closePrivacyModal} />
      <ModalSubmitAlert
        show={isShowSubmitModal}
        close={closeSubmitModal}
        name={inputData.name}
      />

      <ModalDefaultAlert
        show={alertState.isOpen}
        close={handleCloseModalDefaultAlert}
        title={alertState.title}
        content={alertState.content}
      />
    </>
  );
};
