import React, { useEffect, useState } from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";

export const ModalAppDownload = ({ show, close, type, modalTabs, setModalTabs, openSubmitAlert }) => {
  // const [modalTabs, setModalTabs] = useState([
  //   {
  //     name: "Google Play",
  //     active: true,
  //     qr: "https://ebook.nowon.kr/file/pfile/skin/qrcode_20181214220149.png",
  //   },
  //   {
  //     name: "App Store",
  //     active: false,
  //     qr: "https://ebook.nowon.kr/file/pfile/skin/qrcode_20181214220149.png",
  //   },
  // ]);

  const handleTabs = (name) => {
    const updatedTabsChange = modalTabs.map((btn) => {
      if (btn.name === name) {
        return { ...btn, active: true };
      } else {
        return { ...btn, active: false };
      }
    });

    setModalTabs(updatedTabsChange);
  };


  return (
    <div id="DialogAppDownload" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={close}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={close}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>App download</h5>
          </div>

          <span className="line"></span>

          <div className="modal-body">
            <div className="text-group">
              <p>
                Scan the QR code to download <br /> the OnePassFIDO Free Trial app.
              </p>
            </div>

            <>
              <div className="tabs">
                <div className="button-group">
                  {modalTabs.map((btn, idx) => (
                    <div
                      className={`tab-item ${btn.active ? "active" : ""}`}
                      onClick={() => handleTabs(btn.name)}
                      key={idx}
                    >
                      {btn.name}
                    </div>
                  ))}
                </div>
              </div>

              {modalTabs.map((tabs, idx) => (
                <>
                  {tabs.active && (
                    <div className="qr-img" key={idx}>
                      <img src={tabs.qr} alt="qr 이미지" />
                    </div>
                  )}
                </>
              ))}
            </>
          </div>

          <div className="modal-footer">
            <div className="button-group">
              <button className="btn-lg btn-primary-fill" onClick={close}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
