///////////////////////////////////////////////////////////////////////////////
// OnepassFido2Util.js
// =========
//
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
import OnepassFido2Logger  from "./OnepassFido2Logger";
import OnepassFido2ErrorCode from "./OnepassFido2ErrorCode";
import OnepassFido2 from "./OnepassFido2";
import React, {useState} from 'react';
import jQuery from "jquery";
import $ from "jquery";
window.$ = window.jQuery = jQuery;

export const OnepassFido2Util = (() => {
	//'use strict';

	// const [reqResultData, setReqResultData] = useState({
	// 	resultCode: null,
	// 	resultMsg: null,
	// 	resultData: null,
	//   });

	// Decode from base64url string to byte array
	const base64url_decode = (base64urlString) => {
		var decoded = base64urlString
			.replace(/-/g, '+')
			.replace(/_/g, '/');
		var array = fixBase64(decoded);
		return array;
	};

	// Encode from bytes array to base64ul string ('=' add x)
	const base64url_encode = (buffer) => {
		var encoded = btoa(new Uint8Array(buffer)
			.reduce((s, byte) => s + String.fromCharCode(byte), ''))
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_');
		return encoded;
	};

	// Support Base64url function
	const fixBase64 = (binaryData) => {
		var base64str = binaryData;							// base64 string from the response of server
		var binary = atob(base64str.replace(/\s/g, ''));	// decode base64 string, remove space for IE compatibility
		var len = binary.length;         					// get binary length
		var buffer = new ArrayBuffer(len);         			// create ArrayBuffer with binary length
		var view = new Uint8Array(buffer);         			// create 8-bit Array

		// save unicode of binary data into 8-bit Array
		for (var i = 0; i < len; i++)
			view[i] = binary.charCodeAt(i);

		return view;
	}

	// Convert to Hex String from Byte Array
	const convertBytesToHexString = (byteArray) => {
		return Array.from(byteArray, function (byte) {
			return ('0' + (byte & 0xFF).toString(16)).slice(-2);
		}).join('')
	}

	// Convert to JSON String from javascript Object
	const objToString = (obj) => {
		var str = '';
		for (var p in obj) {
			//if (obj.hasOwnProperty(p)) {
			str += '- property::' + p + ' : ' + obj[p] + '\n';
			//}
		}
		return str;
	}

	// Check value is empty
	 const isEmpty = (value) => {
		if (value === "" || value === null || value === undefined
			|| value != null && typeof value === "object" && !Object.keys(value).length) {
			return true;
		} else {
			return false;
		}
	}

	// Generate rpId
	const getRpId = (origin) => {
		var parser = document.createElement("a");
		parser.href = origin;
		return parser.hostname;
	};

	// Generate Unique Random Id
	const getUniqueRandomId = (length) => {
		return Date.now().toString(36) + Math.random().toString(36).substr(2, length);
	}

	const getUserId = (length) => {
		var arrRandom = new Uint8Array(length);
		window.crypto.getRandomValues(arrRandom);
		return base64url_encode(arrRandom);
	}
	 
	 const getSvcTrId = () => {
		var timestamp = (new Date().getTime() % (60 * 60 * 1000)).toString();
		for (var i = timestamp.length; i < 20; i++) {
			timestamp = timestamp + Math.floor(Math.random() * 10);
		}
		return timestamp;
	}

	const getTime = () => {
		var now = new Date();
		let hour = "0" + now.getHours();
		hour = hour.substring(hour.length - 2);
		let minute = "0" + now.getMinutes();
		minute = minute.substring(minute.length - 2);
		let second = "0" + now.getSeconds();
		second = second.substring(second.length - 2);
		let milliSeconds = now.getUTCMilliseconds()
		return getDate() + hour + minute + second + milliSeconds;
	}

	const getDate = () => {
		var now = new Date();
		var year = "" + now.getUTCFullYear();
		var month = "0" + (now.getUTCMonth() + 1);
		month = month.substring(month.length - 2);
		var date = "0" + now.getUTCDate();
		date = date.substring(date.length - 2);
		return year + month + date;
	}

	const getJsonWebToken = (authorizationData) => {
		if (authorizationData == null || authorizationData === "") {
			return "";
		}

		let tokenSeparator = "Bearer";
		let jsonWebToken = "";

		if (authorizationData.search(tokenSeparator) > -1) {
			let jwtIndex = authorizationData.indexOf(tokenSeparator);
			jsonWebToken = authorizationData.substring(jwtIndex + tokenSeparator.length, authorizationData.length);
		}

		return jsonWebToken;
	}

	//useState는 react에서 쓰는 문법이랑 동일해서 따로 function으로 구현해줌
	 const useRequestAjax = () => {
		const reqUrl = "https://onepassdev.raonsecure.co.kr:28445/opf2/rp/getMakeCredential.do";
		const query = OnepassFido2.query();
	// 	const response = axios.post("/api/test", query, {
	// 		headers: {
	// 			"Accept": "application/json",
	// 			"Content-Type": "application/json;charset=utf-8",
	// 		},
	// 	});
		const [reqResultData, setReqResultData] = useState({
			resultCode: null,
			resultMsg: null,
			resultData: null,
		});
		// 	  useEffect(() => {
	// 	// 		const fetchData = async () => {
	// 	// 		  try {
	// 	// 			const response = await axios.post(reqUrl, query, {
	// 	// 			  headers: {
	// 	// 				"Accept": "application/json",
	// 	// 				"Content-Type": "application/json;charset=utf-8",
	// 	// 			  },
	// 	// 			});

	// 	if (response.status === 200) {
	// 		const data = response.data;
	// 		setReqResultData({
	// 			resultCode: data.resultCode,
	// 			resultMsg: data.resultMsg,
	// 			resultData: data.resultData,
	// 		});
	// 	} else {
	// 		const errorObject = OnepassFido2ErrorCode.getError("InternalError");
	// 		setReqResultData({
	// 			resultCode: errorObject.onepassErrCd,
	// 			resultMsg: errorObject.onepassErrMsg,
	// 			resultData: {},
	// 		});
	// 	}
	// 	// 		  } catch (e) {
	// 	// 			const errorObject = OnepassFido2ErrorCode.getError("InternalError");
	// 	// 			setReqResultData({
	// 	// 			  resultCode: errorObject.onepassErrCd,
	// 	// 			  resultMsg: errorObject.onepassErrMsg,
	// 	// 			  resultData: {},
	// 	// 			});
	// 	// 		  }
	// 	// 		};

	// 	// // 	fetchData();
	// 	// //   },[]);

	// 	//   return (
	// 	// 	<div>
	// 	// 	  {/* 데이터를 사용하는 방법 */}
	// 	// 	  <p>결과 코드: {reqResultData.resultCode}</p>
	// 	// 	  <p>결과 메시지: {reqResultData.resultMsg}</p>
	// 	// 	  {/* 결과 데이터를 사용하려면 reqResultData.resultData를 사용하세요 */}
	// 	// 	  console.log({reqResultData.resultData});
	// 	// 	  {reqResultData}
	// 	// 	</div>
	// 	//   );
	// 	// let reqResultData = {
	// 	// 	resultCode : ""
	// 	// 	, resultMsg : ""
	// 	// 	, resultData : {}
	// 	// };

		try {
			$.ajax({
				url: reqUrl
				, headers: {"Accept": "application/json"}
				, crossDomain: true
				, dataType: "json"
				, method: "POST"
				, contentType: "application/json;charset=utf-8"
				, data: JSON.stringify(query)
				, success: function (data) {
					reqResultData.resultCode = data.resultCode;
					reqResultData.resultMsg = data.resultMsg;
					reqResultData.resultData = data.resultData;

					OnepassFido2Logger.debug("OnepassFido2", "return resultData : ", reqResultData);
					return reqResultData;
				}, error: function (jqXHR, textStatus, errorThrown) {
					OnepassFido2Logger.error("OnepassFido2", "REG RESPONSE ERROR" + textStatus);
					let errorObject = OnepassFido2ErrorCode.getError("InternalError");

					reqResultData.resultCode = errorObject.onepassErrCd;
					reqResultData.resultMsg = errorObject.onepassErrMsg;
					reqResultData.resultData = {};

					OnepassFido2Logger.debug("OnepassFido2", "return resultData : ", reqResultData);
					return reqResultData;
				}
			});
		} catch (e) {
			OnepassFido2Logger.error("OnepassFido2", "AJAX ERROR" + e);
			let errorObject = OnepassFido2ErrorCode.getError("InternalError");

			reqResultData.resultCode = errorObject.onepassErrCd;
			reqResultData.resultMsg = errorObject.onepassErrMsg;
			reqResultData.resultData = {};

			OnepassFido2Logger.debug("OnepassFido2", "return resultData : ", reqResultData);
			return reqResultData;
		}
	}

	// Member Functions
	return {
		base64url_decode : base64url_decode
		, base64url_encode : base64url_encode
		, convertBytesToHexString : convertBytesToHexString
		, objToString : objToString
		, isEmpty : isEmpty
		, getRpId : getRpId
		, getUniqueRandomId : getUniqueRandomId
		, getUserId : getUserId
		, getSvcTrId : getSvcTrId
		, getTime : getTime
		, getDate : getDate
		, getJsonWebToken : getJsonWebToken
		, requestAjax : useRequestAjax
	};
})();
export default OnepassFido2Util;
