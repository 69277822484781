import { useEffect, useState } from "react";
import "./App.scss";
import { AppLayout } from "./layout/AppLayout";
import { AppRouter } from "./routes/AppRouter";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const clearSessionStorage = () => {
      sessionStorage.clear(); // 로컬 스토리지의 모든 항목을 지웁니다.
    };

    if (isMobile) {
      console.log("모바일");
      setIsMobile(true);
    } else {
      console.log("PC");
      setIsMobile(false);
    }

    //clearSessionStorage();
  }, []);

  return (
    <AppLayout isMobile={isMobile}>
      <AppRouter isMobile={isMobile} />
    </AppLayout>
  );
}

export default App;
