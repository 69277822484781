import React, { useEffect, useState, useContext, useRef } from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconQR from "../assets/images/popup/img-icon-qr.svg";
import ImgIconAdd from "../assets/images/popup/img-icon-add.svg";
import ImgIconCheck from "../assets/images/popup/img-icon-check.svg";
import {FT_SERVER_URL, OS_VERSION, AAID} from "../constant"
import {hexToByteString, getOsType} from "../js/util"

export const ModalAddFidoAuth = ({ show, close, param, isMobile, openSubmitAlert }) => {

  const [qrImg, setQrImg] = useState('');
  const [isQrExpire, setIsQrExpire] = useState(false);
  const [clock, setClock] = useState(null);
  const [isReg, setIsReg] = useState(true);
  const [sessionMember, setSessionmember] = useState(JSON.parse(sessionStorage.getItem("member")));
  const [appStoreUrl, setAppStoreUrl] = useState('');
  const modalRef = useRef(null);
  
  const getSvcTrId = () => {
    var timestamp = (new Date().getTime() % (60 * 60 * 1000)).toString();
    for (var i = timestamp.length; i < 20; i++) {
      timestamp = timestamp + Math.floor(Math.random() * 10);
    }
    return timestamp;
  }

  const [tdata, setTdata] = useState(null);


  useEffect(() => {
    if (show) {
      if (param !== ''){
        setTdata({
          "command": "requestServiceRegist",
          "bizReqType": "server",
          "svcTrId": getSvcTrId(),
          "siteId": sessionMember.siteId,
          "svcId": sessionMember.svcId,
          "loginId": sessionMember.userId,
          "deviceId": param.deviceId,
          "verifyType": AAID[param.aaid]
        });
      } else {
        setTdata({
          "command": "requestServiceRegist",
          "bizReqType": "server",
          "svcTrId": getSvcTrId(),
          "siteId": sessionMember.siteId,
          "svcId": sessionMember.svcId,
          "loginId": sessionMember.userId
        });
      }
    }
  }, []);

  useEffect(() => {
    register();
  }, [tdata]);


  useEffect(() => {
    // 타이머 작동 로직
    let timerId;
    if (clock !== null && clock > 0) {
      setIsQrExpire(false);
      timerId = setInterval(() => {
        setClock((prevClock) => prevClock - 1);
      }, 1000);
    } else if (clock === 0) {
      clearTimeout();
      // qr이미지가 사라지도록 처리 
      setIsQrExpire(true);
    }

    // 컴포넌트 언마운트 시 타이머 해제
    return () => clearInterval(timerId);
  }, [clock]);

  const register = () => {
    if(tdata){
      fetch(FT_SERVER_URL + '/fidoservice/api/uaf', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tdata),
      })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if(data.resultCode === "000") {

          if(isMobile) {
            callbackForApp(data.resultData.qrImageUrl);
          } else {
            const qrImgStream = data.resultData.qrImage;
            if (qrImgStream) {
              setQrImg('data:image/png;base64,' + qrImgStream);
            }

            const expiretime = data.resultData.expireTime;
            if (expiretime) {
              setClock(expiretime);
            }
          }
        } else {
          openSubmitAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
          close();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openSubmitAlert("FAIL", `error! ${error}`);
      });
    }
  };

  function callbackForApp(qrImgStream) {
    var token = qrImgStream.split('?');
    var enc = token[1].split('=');
    var qrData = hexToByteString(enc[1]);
    const encodeQrString = btoa(qrData);

    setClock(1000 * 10);
  
    switch (getOsType()) {
      case OS_VERSION.OS_ANDROID:
        setAppStoreUrl("intent://digitaltrust?data=" + encodeQrString + "#Intent;scheme=onepassfido;package=com.dtn.onepassfido.trial;end");
        break;
  
      case OS_VERSION.OS_IOS:
        setAppStoreUrl("onepassfido://digitaltrust/data?" + encodeQrString);
        break;
  
      default:
    }
  }

  // 타이머 시간 형식 변환 함수
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds
      }`;
  };

  const callTrResultConfirm = () => {
    // trResultConfirm
    fetch(FT_SERVER_URL + '/fidoservice/api/uaf', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        command: "trResultConfirm",
        svcTrId: tdata.svcTrId,
        protocolFamily: "uaf"
      })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if(data.resultCode === "000"){
          const trStatus = data.resultData.trStatus;

          if (trStatus === "1") {
            setIsReg(!isReg);
          } else {
            openSubmitAlert("FAIL", "["+data.resultCode+"] Authentication has not been completed.");
          }
        } else {
          openSubmitAlert("FAIL", "["+data.resultCode+"] " + data.resultMsg);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openSubmitAlert("FAIL", `error! ${error}`);
      });
  }

  const completeFidoRegistration = () => {
    close();
    window.location.reload();
  }

  const handleImageLoad = () => {
    if(modalRef.current) {
      modalRef.current.style.height = `${modalRef.current.scrollHeight}px`;
    }
  }


  return (
    <div id="DialogAddAuth" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={close}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={close}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>Add new FIDO1.0 Authenticator</h5>
          </div>

          <span className="line"></span>

          {isReg ? (
            <div className="modal-body">
              <div className="img-item">
                <img
                  src={isMobile ? ImgIconAdd : ImgIconQR}
                  alt="스텝이미지01"
                  className="step-img"
                />
              </div>

              <div className="text-group">
                <p>
                  Use the OnePassFIDO Free Trial app to scan the QR code. Add your
                  authenticator.
                </p>
              </div>

              {!isMobile ? (
                <div className="img-item" ref={modalRef}>
                  <div className="qr-img">
                    {qrImg && <img
                      id="qrImg"
                      src={qrImg}
                      style={{ filter: isQrExpire ? 'blur(4px)' : 'none' }}
                      onLoad={handleImageLoad}
                    />}
                  </div>
                </div>
              ) : (
                <p className="open-the-app" onClick={() => window.open(appStoreUrl)}>Open the App</p>
              )}

              {isQrExpire ? (
                <span className="color-error">Authenticate timeout. Please try again.</span>
              ) : (
                <span className="timer color-error">{formatTime(clock)}</span>
              )}
            </div>
          ) : (
            <div className="modal-body">
              <div className="img-item">
                <img
                  src={ImgIconCheck}
                  alt="스텝이미지05"
                  className="step-img"
                />
              </div>
              <div className="text-group">
                <p>
                  FIDO1.0 registration has been completed successfully.
                </p>
              </div>
            </div>
          )}
          {isReg ? (
            <div className="modal-footer">
              <div className="button-group">
                <button disabled={isQrExpire} className="btn-lg btn-primary-fill" onClick={callTrResultConfirm}>
                  Complete Registration
                </button>
              </div>
            </div>
          ) : (
            <div className="modal-footer">
              <div className="button-group">
                <button className="btn-lg btn-primary-fill" onClick={completeFidoRegistration}>
                  OK
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
